import { inject, Injectable, LOCALE_ID } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class SpeechService {
    locale = inject(LOCALE_ID);

    public rates: number[];
    public selectedRate: number;
    public selectedVoice: SpeechSynthesisVoice | null;

    public voices: SpeechSynthesisVoice[];

    lastSpeakText = '';

    constructor() {

        this.voices = [];
        this.rates = [.25, .5, .75, 1, 1.25, 1.5, 1.75, 2];
        this.selectedVoice = null;
        this.selectedRate = .8;

        this.voices = speechSynthesis.getVoices();
        this.selectedVoice = this.locale === 'en' ? (this.voices[0] || null) : this.voices.find(v => v.lang === 'es-MX') || null;

        // Voices may not be available during construction
        if (!this.voices.length) {

            speechSynthesis.addEventListener(
                "voiceschanged",
                () => {
                    this.voices = speechSynthesis.getVoices();
                    this.selectedVoice = this.locale === 'en' ? (this.voices[0] || null) : this.voices.find(v => v.lang === 'es-MX') || null;
                }
            );

        }

    }


    public speak(text: string, stopCurrent: boolean): void {
        this.lastSpeakText = text;

        if (!this.selectedVoice || !text) {
            return;
        }

        if (stopCurrent) {
            this.stop();
        }

        this.synthesizeSpeechFromText(this.selectedVoice, this.selectedRate, text);
    }


    // Stop any running speech synthesis.
    public stop(): void {

        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        }

    }

    public repeat() {
        this.speak(this.lastSpeakText, true);
    }


    // Perform the low-level speech synthesis for the given voice, rate, and text.
    private synthesizeSpeechFromText(
        voice: SpeechSynthesisVoice,
        rate: number,
        text: string
    ): void {

        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.selectedVoice;
        utterance.rate = rate;

        speechSynthesis.speak(utterance);

    }

}