import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Idle } from '@ng-idle/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'career-scope-idle-dialog',
    templateUrl: './idle-dialog.component.html',
    styleUrls: ['./idle-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        NgIf,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        AsyncPipe,
        DatePipe,
    ],
})
export class IdleDialogComponent implements OnDestroy {

  idleState: BehaviorSubject<boolean> = new BehaviorSubject(true);
  idleStartSubscription: Subscription;
  idleEndSubscription: Subscription;


  constructor(
    public dialogRef: MatDialogRef<IdleDialogComponent>,
    public idle: Idle,
    private cd: ChangeDetectorRef
  ) {
    this.idleEndSubscription = idle.onIdleEnd.subscribe(() => {
      this.idleState.next(false);
      this.cd.detectChanges();
    })
    this.idleStartSubscription = idle.onIdleStart.subscribe(() => {
      this.idleState.next(true);
      this.cd.detectChanges();
    });
  }

  stayLoggedIn(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.idleStartSubscription.unsubscribe();
    this.idleEndSubscription.unsubscribe();
  }
}
