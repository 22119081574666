export interface EvalueeActivity {
  uid?: string;
  date: Date;
  action: EvalueeActivityEnum;
  data?: any;
}

export enum EvalueeActivityEnum {
  HIDE_OCCUPATION = 'Hide Occupation',
  SHOW_OCCUPATION = 'Show Occupation',
  SAVE_OCCUPATION = 'Save Occupation',
  UNSAVE_OCCUPATION = 'Unsave Occupation'
}