import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { EvalueeService } from '../_services/evaluee.service';
import { FeaturesSecurityService } from '../_services/features-security.service';

@Injectable({
  providedIn: 'root'
})
export class MyPathGuard implements CanActivate {

  constructor(private es: EvalueeService, private router: Router, private fss: FeaturesSecurityService) { }

  canActivate(): Observable<boolean> {
    return combineLatest([this.es.evaluee, this.fss.evalueeFeatureFlags()]).pipe(
      filter(([evaluee, featureFlags]) => evaluee !== null && featureFlags !== null),
      map(([evaluee, featureFlags]) => {
        if (!featureFlags?.viewMyPath || !evaluee?.interestComplete || !evaluee?.exercisesComplete) {
          this.router.navigateByUrl('results');
          return false;
        }
        return true;
      })
    );
  }

}
