import { Interest, InterestQuestion } from '../models/src/lib/interests';

export const spanishInterests: Interest[] = [
    {
        interestCategory: 'Art',
        name: 'Artístico',
        description: 'Un interés en la expresión creativa de sentimientos o ideas.',
        jobTasks: 'Interés por la expresión creativa de sentimientos o ideas a través de las artes literarias, las artes visuales, las artes escénicas o la artesanía.',
        jobExamples: 'Escritor, Pintor, Actor, Editor, Bailarín, Cantante, Diseñador gráfico, Escenógrafo',
        color: '#ee509c',
        svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
        totalQuestions: 14,
    },
    {
        interestCategory: 'Sci',
        name: 'Científico',
        description: 'Interés por descubrir, recopilar y analizar información sobre el mundo natural, así como por aplicar los resultados de la investigación científica a los problemas de la medicina, las ciencias de la vida y las ciencias naturales.',
        jobTasks: 'Interés por descubrir, recopilar y analizar información sobre el mundo natural y aplicar los resultados de la investigación científica a los problemas de la medicina, las ciencias de la vida y las ciencias naturales.',
        jobExamples: 'Médico Especialista en audición, Veterinario, Biólogo, Químico, Logopeda, Técnico de laboratorio',
        color: '#82c55b',
        svgLogo: '/assets/images/icons/report_interest_scientific_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_scientific_icon_white.svg',

        totalQuestions: 13,
    },
    {
        interestCategory: 'Pla',
        name: 'Plantas/Animales',
        description: 'Interés por trabajar con plantas y animales, normalmente al aire libre.',
        jobTasks: 'Interés por las actividades relacionadas con plantas y animales, generalmente realizadas al aire libre.',
        jobExamples: 'Jardinero, Cuidador de animales, Paisajista Forestal, Cuidador de animales',
        color: '#00a88e',
        svgLogo: 'assets/images/icons/report_interest_plants_icon.svg',
        whiteSvgLogo: 'assets/images/icons/report_interest_plants_icon_white.svg',
        totalQuestions: 11,
    },
    {
        interestCategory: 'Prt',
        name: 'Protector',
        description: 'Interés por utilizar la autoridad para proteger a las personas y los bienes.',
        jobTasks: 'Interés por utilizar la autoridad para proteger a las personas y los bienes.',
        jobExamples: 'Agente de policía, Investigador privado, Guardia de seguridad, guardaespaldas, guardabosques, Funcionario de prisiones',
        color: '#2b3c73',
        svgLogo: '/assets/images/icons/report_interest_protect_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_protect_icon_white.svg',
        totalQuestions: 12,

    },
    {
        interestCategory: 'Mch',
        name: 'Mecánica',
        description: 'Interés por la aplicación de principios mecánicos a situaciones prácticas a través del uso de máquinas o herramientas manuales.',
        jobTasks: 'Interés por aplicar principios mecánicos a situaciones prácticas mediante el uso de máquinas, herramientas manuales o técnicas para producir, construir o reparar objetos.',
        jobExamples: 'Ingeniero eléctrico, Arquitecto, Carpintero, Cocinero, Mecánico, Conductor de ambulancias, Ingeniero de proyectos, Reparador de equipos informáticos',
        color: '#9676b5',
        svgLogo: '/assets/images/icons/report_interest_mechanical_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_mechanical_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Ind',
        name: 'Industrial',
        description: 'Interés por las actividades organizadas, concretas y repetitivas llevadas a cabo en un entorno de producción.',
        jobTasks: 'Interés por las actividades repetitivas concretas organizadas en un ambiente laboral.',
        jobExamples: 'Maquinista, Tintorero, Panadero, Soldador, Obrero Tornero, Empaquetador manual',
        color: '#9aa2ae',
        svgLogo: '/assets/images/icons/report_interest_industiral_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_industiral_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Bus',
        name: 'Detalles de la Empresa',
        description: 'Interés por actividades organizadas y bien definidas que requieran precisión y atención a los detalles, especialmente en un entorno de oficina.',
        jobTasks: 'Interés por actividades organizadas y bien definidas que requieran precisión y atención a los detalles, especialmente en un entorno de oficina.',
        jobExamples: 'Recaudador de facturas, Secretaria, Recepcionista Representante de atención al cliente, Técnico de información sanitaria',
        color: '#006fba',
        svgLogo: '/assets/images/icons/report_interest_business_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_business_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Sel',
        name: 'Venta',
        description: 'Interés por convencer a los demás de un determinado punto de vista mediante la persuasión personal utilizando técnicas de venta y promoción.',
        jobTasks: 'Interés por hacer que los demás compartan un punto de vista mediante la persuasión personal, recurriendo a técnicas de venta y promoción.',
        jobExamples: 'Representante de Ventas, Vendedor en el Estadio ,Vendedor de Ropa, Teleoperador de Ventas Telefónicas, Planificador Financiero Agente de Viajes',
        color: '#005c27',
        svgLogo: '/assets/images/icons/report_interest_selling_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_selling_icon_white.svg',
        totalQuestions: 10,
    },
    {
        interestCategory: 'Acc',
        name: 'Acomodar',
        description: 'Interés en satisfacer los deseos y necesidades de los demás, generalmente en un enfoque individualizado.',
        jobTasks: 'Interés por satisfacer los deseos y necesidades de los demás, normalmente de forma individual, mediante la hospitalidad y el trabajo de servicio.',
        jobExamples: 'Manicurista, Restaurante, Camarero, Camarera, Comprador personal, Auxiliar de vuelo',
        color: '#7d002e',
        svgLogo: '/assets/images/icons/report_interest_accomodating_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_accomodating_icon_white.svg',
        totalQuestions: 10,
    },
    {
        interestCategory: 'Hum',
        name: 'Humanitario',
        description: 'Interés por ayudar a los demás en sus necesidades mentales, espirituales, sociales, físicas o profesionales.',
        jobTasks: 'Interés por ayudar a las personas con sus problemas mentales, sociales, espirituales, físicos y vocacionales mediante terapia o cuidado médico o servicios sociales.',
        jobExamples: 'Auxiliar de atención domiciliaria, Fisioterapeuta, Enfermero, Asistente médico, Auxiliar de cuidado infantil, Asistente de odontología, Consejero, Agente de libertad condicional',
        color: '#69489d',
        svgLogo: '/assets/images/icons/report_interest_humanitarian_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_humanitarian_icon_white.svg',
        totalQuestions: 11,
    },
    {
        interestCategory: 'Ldi',
        name: 'Liderazgo/Influencia',
        description: 'Interés por liderar e influir en los demás utilizando habilidades verbales o numéricas de alto nivel.',
        jobTasks: 'Interés por dirigir e influir en los demás mediante el uso de aptitudes verbales o numéricas de gran nivel en puestos de investigación o gestión en el ámbito de la educación empresarial.',
        jobExamples: 'Administrador de bases de datos, Asistente jurídico, Profesor, Ingeniero en informática, Abogado, Corredor de bolsa, Programador informático',
        color: '#deb407',
        svgLogo: '/assets/images/icons/report_interest_leading_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_leading_icon_white.svg',
        totalQuestions: 14,
    },
    {
        interestCategory: 'Phy',
        name: 'Rendimiento Físico',
        description: 'Interés por las actividades físicas realizadas ante un público, como los deportes o las hazañas físicas audaces.',
        jobTasks: 'Interés por las actividades físicas realizadas ante un público, como los deportes o las hazañas físicas audaces.',
        jobExamples: 'Atleta, Entrenador, Doble de cine, Malabarista, Instructor deportivo',
        color: '#00aeef',
        svgLogo: '/assets/images/icons/report_interest_physical_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_physical_icon_white.svg',
        totalQuestions: 10,
    }
];

// Modernized version of interest inventory
// Updated 2/2023
export const spanishInterestQuestions: InterestQuestion[] = [
  { id: 1, question: 'Crear diseños gráficos', interestCategory: 'Art', order: 1 },
  { id: 2, question: 'Analizar la pureza de las muestras de agua', interestCategory: 'Sci', order: 2 },
  { id: 3, question: 'Cultivar árboles en una plantación', interestCategory: 'Pla', order: 3 },
  { id: 4, question: 'Patrullar parques y zonas de juego para mantenerlos seguros', interestCategory: 'Prt', order: 4 },
  { id: 5, question: 'Diseñar la construcción de puentes', interestCategory: 'Mch', order: 5 },
  { id: 6, question: 'Controlar una máquina que recicla plástico', interestCategory: 'Ind', order: 6 },
  { id: 7, question: 'Organizar documentos', interestCategory: 'Bus', order: 7 },
  { id: 8, question: 'Vender seguros', interestCategory: 'Sel', order: 8 },
  { id: 9, question: 'Recibir y atender a los clientes del restaurante', interestCategory: 'Acc', order: 9 },
  { id: 10, question: 'Impartir clases de primeros auxilios', interestCategory: 'Hum', order: 10 },
  { id: 11, question: 'Enseñar a los alumnos en una escuela', interestCategory: 'Ldi', order: 11 },
  { id: 12, question: 'Entrenar a un equipo universitario de baloncesto', interestCategory: 'Phy', order: 12 },
  { id: 13, question: 'Restaurar obras de arte dañadas', interestCategory: 'Art', order: 13 },
  { id: 14, question: 'Investigar la causa de las enfermedades', interestCategory: 'Sci', order: 14 },
  { id: 15, question: 'Alimentar y cuidar a perros y gatos en una perrera', interestCategory: 'Pla', order: 15 },
  { id: 16, question: 'Vigilar personas en una prisión', interestCategory: 'Prt', order: 16 },
  { id: 17, question: 'Diseñar máquinas que fabriquen nuevos productos', interestCategory: 'Mch', order: 17 },
  { id: 18, question: 'Ordenar el tejido por color', interestCategory: 'Ind', order: 18 },
  { id: 19, question: 'Registrar pacientes en una consulta médica', interestCategory: 'Bus', order: 19 },
  { id: 20, question: 'Comprar productos para una cadena de tiendas', interestCategory: 'Sel', order: 20 },
  { id: 21, question: 'Hacer visitas guiadas a lugares emblemáticos de la ciudad', interestCategory: 'Acc', order: 21 },
  { id: 22, question: 'Orientar a los estudiantes sobre su carrera profesional', interestCategory: 'Hum', order: 22 },
  { id: 23, question: 'Entrevistar a las personas que solicitan empleo', interestCategory: 'Ldi', order: 23 },
  { id: 24, question: 'Ejercer de árbitro en un acontecimiento deportivo', interestCategory: 'Phy', order: 24 },
  { id: 25, question: 'Escribir anuncios de productos para podcasts', interestCategory: 'Art', order: 25 },
  { id: 26, question: 'Operar a los pacientes', interestCategory: 'Sci', order: 26 },
  { id: 27, question: 'Asear perros para una exposición canina', interestCategory: 'Pla', order: 27 },
  { id: 28, question: 'Controlar las aglomeraciones en un estadio', interestCategory: 'Prt', order: 28 },
  { id: 29, question: 'Dirigir las obras de reparación de túneles y carreteras', interestCategory: 'Mch', order: 29 },
  { id: 30, question: 'Instalar máquinas en una fábrica', interestCategory: 'Ind', order: 30 },
  { id: 31, question: 'Preparar solicitudes de préstamo', interestCategory: 'Bus', order: 31 },
  { id: 32, question: 'Ayudar a los compradores a seleccionar productos para el hogar', interestCategory: 'Sel', order: 32 },
  { id: 33, question: 'Atender a los clientes en un restaurante', interestCategory: 'Acc', order: 33 },
  { id: 34, question: 'Trabajar con pacientes para mejorar su movilidad física', interestCategory: 'Hum', order: 34 },
  { id: 35, question: 'Recoger las opiniones de los clientes sobre los productos', interestCategory: 'Ldi', order: 35 },
  { id: 36, question: 'Practicar un deporte profesional', interestCategory: 'Phy', order: 36 },
  { id: 37, question: 'Diseñar decorados de películas', interestCategory: 'Art', order: 37 },
  { id: 38, question: 'Examinar muestras de sangre con un microscopio', interestCategory: 'Sci', order: 38 },
  { id: 39, question: 'Plantar y abonar los cultivos', interestCategory: 'Pla', order: 39 },
  { id: 40, question: 'Interrogar a los testigos de un delito', interestCategory: 'Prt', order: 40 },
  { id: 41, question: 'Inspeccionar terrenos para cartografiar solares', interestCategory: 'Mch', order: 41 },
  { id: 42, question: 'Supervisar una máquina que coloca etiquetas en las cajas', interestCategory: 'Ind', order: 42 },
  { id: 43, question: 'Mecanografiar documentos para un abogado', interestCategory: 'Bus', order: 43 },
  { id: 44, question: 'Asesorar sobre la elección de un teléfono móvil', interestCategory: 'Sel', order: 44 },
  { id: 45, question: 'Asistir a los viajeros en un aeropuerto', interestCategory: 'Acc', order: 45 },
  { id: 46, question: 'Visitar el domicilio de pacientes para prestarles asistencia médica a diario', interestCategory: 'Hum', order: 46 },
  { id: 47, question: 'Redactar las funciones básicas de los puestos de trabajo', interestCategory: 'Ldi', order: 47 },
  { id: 48, question: 'Entrenar a un atleta profesional', interestCategory: 'Phy', order: 48 },
  { id: 49, question: 'Dirigir una obra de teatro', interestCategory: 'Art', order: 49 },
  { id: 50, question: 'Estudiar el movimiento de los planetas', interestCategory: 'Sci', order: 50 },
  { id: 51, question: 'Preparar comida para animales de zoológico', interestCategory: 'Pla', order: 51 },
  { id: 52, question: 'Rescatar a personas en el lugar del accidente', interestCategory: 'Prt', order: 52 },
  { id: 53, question: 'Instalar tuberías y accesorios de fontanería', interestCategory: 'Mch', order: 53 },
  { id: 54, question: 'Descargar productos de una cinta en movimiento', interestCategory: 'Ind', order: 54 },
  { id: 55, question: 'Comprobar si hay errores en los registros de facturación', interestCategory: 'Bus', order: 55 },
  { id: 56, question: 'Demostrar los productos a los compradores', interestCategory: 'Sel', order: 56 },
  { id: 57, question: 'Tomar pedidos de clientes en un restaurante de comida rápida', interestCategory: 'Acc', order: 57 },
  { id: 58, question: 'Ofrecer sesiones de terapia artística', interestCategory: 'Hum', order: 58 },
  { id: 59, question: 'Presentar un caso ante un tribunal', interestCategory: 'Ldi', order: 59 },
  { id: 60, question: 'Enseñar a los niños a hacer deporte', interestCategory: 'Phy', order: 60 },
  { id: 61, question: 'Dirigir un coro', interestCategory: 'Art', order: 61 },
  { id: 62, question: 'Diagnosticar y tratar problemas de visión', interestCategory: 'Sci', order: 62 },
  { id: 63, question: 'Cuidar animales en una tienda de mascotas', interestCategory: 'Pla', order: 63 },
  { id: 64, question: 'Prevenir los robos en las tiendas', interestCategory: 'Prt', order: 64 },
  { id: 65, question: 'Reparar motores', interestCategory: 'Mch', order: 65 },
  { id: 66, question: 'Inspeccionar la calidad de los productos antes de envasarlos', interestCategory: 'Ind', order: 66 },
  { id: 67, question: 'Calcular el salario de los empleados', interestCategory: 'Bus', order: 67 },
  { id: 68, question: 'Proporcionar muestras de productos a las empresas', interestCategory: 'Sel', order: 68 },
  { id: 69, question: 'Registrar a los huéspedes de un hotel', interestCategory: 'Acc', order: 69 },
  { id: 70, question: 'Prestar asistencia a pacientes hospitalizados', interestCategory: 'Hum', order: 70 },
  { id: 71, question: 'Hacer investigaciones jurídicas', interestCategory: 'Ldi', order: 71 },
  { id: 72, question: 'Realizar acrobacias en un espectáculo', interestCategory: 'Phy', order: 72 },
  { id: 73, question: 'Organizar rutinas de baile', interestCategory: 'Art', order: 73 },
  { id: 74, question: 'Realizar experimentos químicos', interestCategory: 'Sci', order: 74 },
  { id: 75, question: 'Cosechar los cultivos', interestCategory: 'Pla', order: 75 },
  { id: 76, question: 'Recoger pruebas en la escena del crimen', interestCategory: 'Prt', order: 76 },
  { id: 77, question: 'Construir armarios en viviendas', interestCategory: 'Mch', order: 77 },
  { id: 78, question: 'Poner en marcha una máquina que corte roscas en tornillos', interestCategory: 'Ind', order: 78 },
  { id: 79, question: 'Preparar las facturas', interestCategory: 'Bus', order: 79 },
  { id: 80, question: 'Planificar los contratos de venta', interestCategory: 'Sel', order: 80 },
  { id: 81, question: 'Acompañar al público a sus asientos', interestCategory: 'Acc', order: 81 },
  { id: 82, question: 'Enseñar a alguien a utilizar una prótesis', interestCategory: 'Hum', order: 82 },
  { id: 83, question: 'Diseñar estrategias para optimizar el flujo de información dentro de una empresa', interestCategory: 'Ldi', order: 83 },
  { id: 84, question: 'Realizar acrobacias cinematográficas', interestCategory: 'Phy', order: 84 },
  { id: 85, question: 'Diseñar anuncios para redes sociales', interestCategory: 'Art', order: 85 },
  { id: 86, question: 'Probar una nueva vacuna', interestCategory: 'Sci', order: 86 },
  { id: 87, question: 'Cuidar animales en una granja', interestCategory: 'Pla', order: 87 },
  { id: 88, question: 'Detener a personas sospechosas de delitos', interestCategory: 'Prt', order: 88 },
  { id: 89, question: 'Reparar e instalar cerraduras', interestCategory: 'Mch', order: 89 },
  { id: 90, question: 'Operar una máquina que fabrica papel', interestCategory: 'Ind', order: 90 },
  { id: 91, question: 'Leer códigos de barras de artículos para procesar el pago en una tienda', interestCategory: 'Bus', order: 91 },
  { id: 92, question: 'Organizar la presentación de casas a nuevos compradores', interestCategory: 'Sel', order: 92 },
  { id: 93, question: 'Llevar a la gente a los aeropuertos, hoteles y eventos', interestCategory: 'Acc', order: 93 },
  { id: 94, question: 'Ayudar a las personas a acceder a programas de formación laboral', interestCategory: 'Hum', order: 94 },
  { id: 95, question: 'Crear programas de seguridad para proteger las redes informáticas', interestCategory: 'Ldi', order: 95 },
  { id: 96, question: 'Competir en carreras de motos', interestCategory: 'Phy', order: 96 },
  { id: 97, question: 'Escribir guiones para películas', interestCategory: 'Art', order: 97 },
  { id: 98, question: 'Estudiar el crecimiento de las células', interestCategory: 'Sci', order: 98 },
  { id: 99, question: 'Cuidar las flores, plantas o árboles', interestCategory: 'Pla', order: 99 },
  { id: 100, question: 'Guardar dinero en un banco', interestCategory: 'Prt', order: 100 },
  { id: 101, question: 'Controlar las turbinas que producen electricidad', interestCategory: 'Mch', order: 101 },
  { id: 102, question: 'Doblar ropa en una fábrica', interestCategory: 'Ind', order: 102 },
  { id: 103, question: 'Introducir datos en hojas de cálculo', interestCategory: 'Bus', order: 103 },
  { id: 104, question: 'Explicar a los asistentes de una convención los usos y los costos de los productos', interestCategory: 'Sel', order: 104 },
  { id: 105, question: 'Gestionar las reservas de restaurantes', interestCategory: 'Acc', order: 105 },
  { id: 106, question: 'Cuidar niños en una guardería', interestCategory: 'Hum', order: 106 },
  { id: 107, question: 'Dar consejos sobre cómo invertir el dinero', interestCategory: 'Ldi', order: 107 },
  { id: 108, question: 'Entrenador de eventos de atletismo', interestCategory: 'Phy', order: 108 },
  { id: 109, question: 'Filmar sucesos para las noticias', interestCategory: 'Art', order: 109 },
  { id: 110, question: 'Diseñar formas de controlar las enfermedades de las plantas', interestCategory: 'Sci', order: 110 },
  { id: 111, question: 'Trabajadores de una granja lechera', interestCategory: 'Pla', order: 111 },
  { id: 112, question: 'Investigar a sospechosos de delitos', interestCategory: 'Prt', order: 112 },
  { id: 113, question: 'Reparar aparatos domésticos', interestCategory: 'Mch', order: 113 },
  { id: 114, question: 'Verificar el espesor de las chapas a medida que se fabrican', interestCategory: 'Ind', order: 114 },
  { id: 115, question: 'Clasificar el correo que debe enviarse a los distintos departamentos', interestCategory: 'Bus', order: 115 },
  { id: 116, question: 'Realizar un curso de formación en línea sobre marketing y ventas', interestCategory: 'Sel', order: 116 },
  { id: 117, question: 'Cortar y peinar el cabello de un cliente', interestCategory: 'Acc', order: 117 },
  { id: 118, question: 'Organizar eventos sociales en un centro para personas mayores', interestCategory: 'Hum', order: 118 },
  { id: 119, question: 'Realizar investigaciones sobre delitos informáticos', interestCategory: 'Ldi', order: 119 },
  { id: 120, question: 'Realizar actos de riesgo en las carreras de autos', interestCategory: 'Phy', order: 120 },
  { id: 121, question: 'Impartir clases de teatro', interestCategory: 'Art', order: 121 },
  { id: 122, question: 'Estudiar la atmósfera de los planetas', interestCategory: 'Sci', order: 122 },
  { id: 123, question: 'Envasar frutas y verduras de una granja', interestCategory: 'Pla', order: 123 },
  { id: 124, question: 'Custodiar las obras de arte en un museo', interestCategory: 'Prt', order: 124 },
  { id: 125, question: 'Empaquetar productos para su envío desde un almacén', interestCategory: 'Ind', order: 125 },
  { id: 126, question: 'Responder y transferir llamadas para una empresa', interestCategory: 'Bus', order: 126 },
  { id: 127, question: 'Asesorar a las personas que buscan apoyo en materia de salud mental', interestCategory: 'Hum', order: 127 },
  { id: 128, question: 'Organizar programas para un distrito escolar', interestCategory: 'Ldi', order: 128 },
  { id: 129, question: 'Esculpir materiales como arcilla, madera o piedra', interestCategory: 'Art', order: 129 },
  { id: 130, question: 'Investigar el valor nutricional de los alimentos de origen vegetal', interestCategory: 'Sci', order: 130 },
  { id: 131, question: 'Enseñar a los nuevos policías a investigar delitos', interestCategory: 'Prt', order: 131 },
  { id: 132, question: 'Instalar paneles de yeso en edificios', interestCategory: 'Mch', order: 132 },
  { id: 133, question: 'Utilizar una máquina que utiliza láser para soldar piezas metálicas', interestCategory: 'Ind', order: 133 },
  { id: 134, question: 'Llevar los registros financieros de una pequeña empresa', interestCategory: 'Bus', order: 134 },
  { id: 135, question: 'Dirigir una campaña de recaudación de fondos para una gran universidad', interestCategory: 'Ldi', order: 135 },
  { id: 136, question: 'Pintar y hacer esmalte de cerámica', interestCategory: 'Art', order: 136 },
  { id: 137, question: 'Diagnosticar y tratar cánceres', interestCategory: 'Sci', order: 137 },
  { id: 138, question: 'Adquirir equipamiento para una empresa', interestCategory: 'Ldi', order: 138 },
  { id: 139, question: 'Componer música', interestCategory: 'Art', order: 139 },
  { id: 140, question: 'Operar tractores pesados y grúas', interestCategory: 'Mch', order: 140 },
  { id: 141, question: 'Ayudar a gestionar las finanzas de una empresa', interestCategory: 'Ldi', order: 141 },
];