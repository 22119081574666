import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { take, map, of} from 'rxjs';
import { AuthService } from '../_services/auth.service';

export const allowLoginPageGuard = (
  next: ActivatedRouteSnapshot
) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const invitationId = next.paramMap.get('uuid');

  // Potentially refactor to load pre-registration invitation here

  if (invitationId) {
    return of(true);
  }

  return auth.user$.pipe(
    take(1),
    map(user => {
      if (user) {
        router.navigate(['/']);
        return false;
      }
      return true;
    })
  );
};
