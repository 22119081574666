import { computed, inject, Injectable, LOCALE_ID, Signal, signal } from '@angular/core';
import { EvalueeAnswer, Interest, InterestQuestion, InterestScore } from '@career-scope/models';
import { interests, interestQuestions } from '../../../../../libs/data/populateInterests';
import { spanishInterestQuestions, spanishInterests } from '../../../../../libs/data/spanishPopulateInterests';

@Injectable({
  providedIn: 'root'
})
export class InterestService {
  locale = inject(LOCALE_ID);
  allInterests = signal<Interest[]>([]);
  allInterestQs = signal<InterestQuestion[]>([]);
  
  consumerDirectEvaluee = signal(false);
  interests = computed(() => !this.consumerDirectEvaluee() ? this.allInterests() : this.allInterests().filter(i => i.interestCategory !== 'Phy'));
  private interestQs = computed(() => !this.consumerDirectEvaluee() ? this.allInterestQs() : this.allInterestQs().filter(q => q.interestCategory !== 'Phy'));
  interestsLength = computed(() => this.interests().length);
  interestQsLength = computed(() => this.interestQs().length > 140 ? 140 : 130);

  topInterests = signal<(Interest & InterestScore)[]>([]);

  INTEREST_INVENTORY_TASK_ID = 16;


  constructor() {
    this.allInterests.set(this.locale === 'en' ? interests : spanishInterests);
    this.allInterestQs.set(this.locale === 'en' ? interestQuestions : spanishInterestQuestions);
    // Calculate total # of questions in each interest category
    // this.interests.forEach(interest =>{
    //   interest.totalQuestions = this.interestQs.filter(i => i.interestCategory === interest.interestCategory).length;
    // });
  }

  setTopInterests(topInterestScores: InterestScore[]) {
    this.topInterests.set(topInterestScores.map(score => {
      const matchingInterest = this.interests().find(interest => interest.interestCategory === score.interestCategory) as Interest;
      return {
        ...score,
        ...matchingInterest,
      };
    }));
  }

  getInterestQuestions(): Signal<InterestQuestion[]> {
    return this.interestQs;
  }

  setInterestInventoryAnswers(evalueeAnswers: EvalueeAnswer[]) {
    const interestAnswers = evalueeAnswers.filter(answer => answer.taskId === this.INTEREST_INVENTORY_TASK_ID);

    interestAnswers.forEach(answer => {
      const serviceQuestion = this.interestQs().find(q => q.id === answer.questionId);
      if (serviceQuestion) {
        serviceQuestion.answer = answer.answer;
      } else {
        // console.log('Interest question id:', answer.questionId, 'not found');
      }
    });
  }

  getInterestInventoryAnswers(): EvalueeAnswer[] {
    return this.interestQs().filter(q => !!q.answer).map(q => ({
      taskId: this.INTEREST_INVENTORY_TASK_ID,
      questionId: q.id,
      answer: q.answer as number,
      timeToComplete: q.timeToComplete ? Math.floor(q.timeToComplete) : 0,
    }));
  }

  hasInterestInventoryProgress(): boolean {
    return this.interestQs().some(q => q.answer);
  }

  resetInterestInventoryAnswers() {
    this.interestQs().forEach(q => q.answer = null);
  }

  completeInterestQuestions() {
    this.interestQs().forEach(q => {
      q.answer = Math.floor(Math.random() * 3 + 1);
    });
  }
}
