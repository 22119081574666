<div mat-dialog-title i18n>Your session is about to expire!</div>
<div mat-dialog-content>

  <ng-container *ngIf="idle.onTimeoutWarning | async as countdown; else placeHolder">
    <p class="countdown" *ngIf="idleState | async; else placeHolder" i18n>You will be logged out in {{countdown * 1000 | date:'m:ss'}}</p>
  </ng-container>

</div>
<div mat-dialog-actions>

  <button mat-button color="warn" mat-dialog-close i18n>Logout</button>
  <button mat-flat-button color="primary" cdkFocusInitial (click)="stayLoggedIn()" style="margin-left: auto;" i18n>Stay logged in</button>

</div>

<ng-template #placeHolder>
  <p class="countdown" i18n>You will be logged out in</p>
</ng-template>