import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { aptitudeTasks } from '../../../../../../libs/data/populateAptitudes';
import { map, Observable, of, skipWhile, take } from 'rxjs';
import { AssessmentService } from '../../_services/assessment.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentCompletedGuard implements CanActivate {

  constructor(private assessmentService: AssessmentService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const taskId = route.params['task'];

    if(!aptitudeTasks.map(task => task.id.toString()).includes(taskId)) {
      this.routeToHome();
      return of(false);
    };

    return this.assessmentService.assessment.pipe(
      skipWhile(assessment => assessment === null),
      take(1),
      map(assessment => {
        if(!assessment) {
          this.routeToHome();
          return false;
        }

        const taskResult = assessment.taskResults.find(task => task.taskId == taskId);

        // Allow routing if no taskResult for taskId or if no completedDate on taskResult for taskId
        if(!taskResult || !taskResult.completedDate) {
          return true;
        }

        this.routeToHome();
        return false;
      })
    );
  }

  routeToHome() {
    this.router.navigateByUrl('/');
  }

}


