import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Evaluee } from '@career-scope/models';
import { AUDIO } from './audio-data';
import { EvalueeService } from './evaluee.service';
import { SpeechService } from './speech-synthesis';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  locale = inject(LOCALE_ID);
  currentUrl = "";

  constructor(private router: Router, private speechService: SpeechService, private es: EvalueeService) {
    this.initAudio();
  }


  initAudio() {
    this.router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        this.currentUrl = res.url;
        this.es.evaluee.pipe(filter(e => !!e), take(1)).subscribe(es => {
          if (es?.audioEnabled) {
            this.startAudio();
          }
        });
      }
    });
  }

  public displayControls(show: boolean) {
    this.es.saveAudioEnabled(show);
    if (show) {
      this.startAudio();
    }
    if (!show) {
      this.stopAudio();
    }
  }

  private startAudio() {
    const evaluee = this.es.evaluee.value;
    const text = this.getAudio(this.currentUrl, evaluee);
    this.speak(text, true);
  }

  speak(text: string, stopCurrent: boolean) {
    this.speechService.speak(text, stopCurrent);
  }

  repeat() {
    this.speechService.repeat();
  }

  stopAudio() {
    this.speechService.stop();
  }

  getAudio(url: string, evaluee: Evaluee | null | undefined): string {
    const consumerDirectEvaluee = evaluee?.consumerDirect || false;

    if (url === '/interests') {
      if (evaluee?.interestComplete && !evaluee?.exercisesComplete) {
        return this.getAudioText(url, 2, consumerDirectEvaluee);
      }
      if (evaluee?.interestComplete && evaluee?.exercisesComplete) {
        return this.getAudioText(url, 3, consumerDirectEvaluee);
      }
      return this.getAudioText(url, 1, consumerDirectEvaluee);
    }

    if (url === '/exercises') {
      if (evaluee?.exercisesComplete && !evaluee?.interestComplete) {
        return this.getAudioText(url, 2, consumerDirectEvaluee);
      }
      if (evaluee?.exercisesComplete && evaluee?.interestComplete) {
        return this.getAudioText(url, 3, consumerDirectEvaluee);
      }
      return this.getAudioText(url, 1, consumerDirectEvaluee);
    }

    if (url === '/results') {
      if (!evaluee?.exercisesComplete || !evaluee?.interestComplete) {
        return this.getAudioText(url, 1, consumerDirectEvaluee);
      }
      if (evaluee.assessmentSettings?.hideResultOnCompletion) {
        return this.getAudioText(url, 3, consumerDirectEvaluee);
      }
      return this.getAudioText(url, 2, consumerDirectEvaluee);
    }

    if (url.includes('apttest')) {
      return this.getAudioText(url, 1, consumerDirectEvaluee);
    }

    return this.getAudioText(url, null, consumerDirectEvaluee);
  }

  getAudioText(url: string, secondary: number | null, consumerDirect = false): string {
    const audio = AUDIO.find(a => a.url === url && (secondary ? a.secondary === secondary : true));

    if (this.locale === 'en') {
      return consumerDirect ? audio?.consumerDirectText || audio?.text || '' : audio?.text || '';
    }

    return consumerDirect ? audio?.consumerDirectSpanishText || audio?.spanishText || '' : audio?.spanishText || '';
  }

}
