
// import { artisticIcon, scientificIcon, plantsIcon, protectIcon, mechanicalIcon, industrialIcon, businessIcon, sellingIcon, accommodatingIcon, humanitarianIcon, leadingIcon, physicalIcon } from './svg';

import { Interest, InterestQuestion } from '@career-scope/models';

export const interests: Interest[] = [
    {
        interestCategory: 'Art',
        name: 'Artistic',
        description: 'An interest in the creative expression of feelings or ideas.',
        jobTasks: 'An interest in creative expression of feeling or ideas through literary arts, visual arts, performing arts, or crafts.',
        jobExamples: 'Writer, Painter, Actor, Editor, Dancer, Singer, Graphic Designer, Set Designer',
        color: '#ee509c',
        svgLogo: '/assets/images/icons/report_interest_artistic_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_artistic_icon_white.svg',
        totalQuestions: 14,
    },
    {
        interestCategory: 'Sci',
        name: 'Scientific',
        description: 'An interest in discovering, collecting, and analyzing information about the natural world, and in applying scientific research findings to problems in medicine, the life sciences, and the natural sciences.',
        jobTasks: 'An interest in discovering, collecting, and analyzing information about the natural world and applying scientific research findings to problems in medicine, the life sciences, and the natural sciences.',
        jobExamples: 'Physician, Audiologist, Veterinarian, Biologist, Chemist, Speech Pathologist, Laboratory Technician',
        color: '#82c55b',
        svgLogo: '/assets/images/icons/report_interest_scientific_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_scientific_icon_white.svg',

        totalQuestions: 13,
    },
    {
        interestCategory: 'Pla',
        name: 'Plants/Animals',
        description: 'An interest in working with plants and animals, usually outdoors.',
        jobTasks: 'An interest in activities involving plants and animals, usually in an outdoor setting.',
        jobExamples: 'Gardner, Animal Groomer, Landscaper, Forester, Animal Caretaker',
        color: '#00a88e',
        svgLogo: 'assets/images/icons/report_interest_plants_icon.svg',
        whiteSvgLogo: 'assets/images/icons/report_interest_plants_icon_white.svg',
        totalQuestions: 11,
    },
    {
        interestCategory: 'Prt',
        name: 'Protective',
        description: 'An interest in using authority to protect people and property.',
        jobTasks: 'An interest in using authority to protect people and property.',
        jobExamples: 'Police Officer, Private Investigator, Security Guard, Bodyguard, Park Ranger, Correctional Officer',
        color: '#2b3c73',
        svgLogo: '/assets/images/icons/report_interest_protect_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_protect_icon_white.svg',
        totalQuestions: 12,

    },
    {
        interestCategory: 'Mch',
        name: 'Mechanical',
        description: 'An interest in applying mechanical principles to practical situations by use of machines or hand tools.',
        jobTasks: 'An interest in applying mechanical principles to practical situations using machines, hand-tools, or techniques to produce, build, or repair things.',
        jobExamples: 'Electrical Engineer, Architect, Carpenter, Chef, Mechanic, Ambulance Driver, Project Engineer, Computer Equipment Repairer',
        color: '#9676b5',
        svgLogo: '/assets/images/icons/report_interest_mechanical_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_mechanical_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Ind',
        name: 'Industrial',
        description: 'An interest in repetitive, concrete, organized activities done in a factory setting.',
        jobTasks: 'An interest in repetitive, concrete, organized activities in a factory setting.',
        jobExamples: 'Machinist, Dry Cleaner, Baker, Welder, Laborer, Lathe Operator, Hand Packager',
        color: '#9aa2ae',
        svgLogo: '/assets/images/icons/report_interest_industiral_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_industiral_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Bus',
        name: 'Business Detail',
        description: 'An interest in organized, clearly defined activities requiring accuracy and attention to details, primarily in an office setting.',
        jobTasks: 'An interest in organized, clearly defined activities requiring accuracy and attention to details, primarily in an office setting.',
        jobExamples: 'Bill Collector, Secretary, Receptionist, Customer Service Representative, Health Information Technician',
        color: '#006fba',
        svgLogo: '/assets/images/icons/report_interest_business_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_business_icon_white.svg',
        totalQuestions: 12,
    },
    {
        interestCategory: 'Sel',
        name: 'Selling',
        description: 'An interest in bringing others to a particular point of view by personal persuasion, using sales and promotional techniques.',
        jobTasks: 'An interest in bringing others to a point of view by personal persuasion, using sales and promotional techniques.',
        jobExamples: 'Sales Representative, Stadium Vendor, Clothing Salesperson, Telephone Solicitor, Financial Planner, Travel Agent',
        color: '#005c27',
        svgLogo: '/assets/images/icons/report_interest_selling_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_selling_icon_white.svg',
        totalQuestions: 10,
    },
    {
        interestCategory: 'Acc',
        name: 'Accommodating',
        description: 'An interest in catering to the wishes and needs of others, usually on a one-to-one basis.',
        jobTasks: 'An interest in catering to the wishes and needs of others, usually on a one-to-one basis, through hospitality and service work.',
        jobExamples: 'Manicurist, Restaurant Host, Waiter, Waitress, Personal Shopper, Flight Attendant',
        color: '#7d002e',
        svgLogo: '/assets/images/icons/report_interest_accomodating_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_accomodating_icon_white.svg',
        totalQuestions: 10,
    },
    {
        interestCategory: 'Hum',
        name: 'Humanitarian',
        description: 'An interest in helping others with their mental, spiritual, social, physical, or vocational needs.',
        jobTasks: 'An interest in helping individuals with their mental, social, spiritual, physical and vocational concerns, through medical or social services, therapy, or nursing.',
        jobExamples: 'Home Care Aide, Physical Therapist, Nurse, Medical Assistant, Child Care Worker, Dental Hygienist, Counselor, Probation Officer',
        color: '#69489d',
        svgLogo: '/assets/images/icons/report_interest_humanitarian_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_humanitarian_icon_white.svg',
        totalQuestions: 11,
    },
    {
        interestCategory: 'Ldi',
        name: 'Leading/Influencing',
        description: 'An interest in leading and influencing others by using high-level verbal or numerical abilities.',
        jobTasks: 'An interest in leading and influencing others by using high- level verbal or numerical abilities in business, education, research, or management positions.',
        jobExamples: 'Database Administrator, Paralegal, Teacher, Computer Engineer, Lawyer, Stock Broker, Computer Programmer',
        color: '#deb407',
        svgLogo: '/assets/images/icons/report_interest_leading_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_leading_icon_white.svg',
        totalQuestions: 14,
    },
    {
        interestCategory: 'Phy',
        name: 'Physical Performing',
        description: 'An interest in physical activities performed before an audience, such as sports or daring physical feats.',
        jobTasks: 'An interest in physical activities performed before an audience, such as sports or daring physical feats.',
        jobExamples: 'Athlete, Coach, Movie Stunt Performer, Juggler, Sports Instructor',
        color: '#00aeef',
        svgLogo: '/assets/images/icons/report_interest_physical_icon.svg',
        whiteSvgLogo: '/assets/images/icons/report_interest_physical_icon_white.svg',
        totalQuestions: 10,
    }
];

export const onetIP: InterestQuestion[] = [
    { id: 1000, question: 'Build kitchen cabinets', RIASECCategory: 'Realistic', order: 1 },
    { id: 1001, question: 'Lay brick or tile', RIASECCategory: 'Realistic', order: 2 },
    { id: 1002, question: 'Repair household appliances', RIASECCategory: 'Realistic', order: 13 },
    { id: 1003, question: 'Raise fish in a fish hatchery', RIASECCategory: 'Realistic', order: 14 },
    { id: 1004, question: 'Assemble electronic parts', RIASECCategory: 'Realistic', order: 25 },
    { id: 1005, question: 'Drive a truck to deliver packages to offices and homes', RIASECCategory: 'Realistic', order: 26 },
    { id: 1006, question: 'Test the quality of parts before shipment', RIASECCategory: 'Realistic', order: 37 },
    { id: 1007, question: 'Repair and install locks', RIASECCategory: 'Realistic', order: 38 },
    { id: 1008, question: 'Set up and operate machines to make products', RIASECCategory: 'Realistic', order: 49 },
    { id: 1009, question: 'Put out forest fires', RIASECCategory: 'Realistic', order: 50 },
    { id: 1010, question: 'Develop a new medicine', RIASECCategory: 'Investigative', order: 3 },
    { id: 1011, question: 'Study ways to reduce water pollution', RIASECCategory: 'Investigative', order: 4 },
    { id: 1012, question: 'Conduct chemical experiments', RIASECCategory: 'Investigative', order: 15 },
    { id: 1013, question: 'Study the movement of planets', RIASECCategory: 'Investigative', order: 16 },
    { id: 1014, question: 'Examine blood samples using a microscope', RIASECCategory: 'Investigative', order: 27 },
    { id: 1015, question: 'Investigate the cause of a fire', RIASECCategory: 'Investigative', order: 28 },
    { id: 1016, question: 'Develop a way to better predict the weather', RIASECCategory: 'Investigative', order: 39 },
    { id: 1017, question: 'Work in a biology lab', RIASECCategory: 'Investigative', order: 40 },
    { id: 1018, question: 'Invent a replacement for sugar', RIASECCategory: 'Investigative', order: 51 },
    { id: 1019, question: 'Do laboratory tests to identify diseases', RIASECCategory: 'Investigative', order: 52 },
    { id: 1020, question: 'Write books or plays', RIASECCategory: 'Artistic', order: 5 },
    { id: 1021, question: 'Play a musical instrument', RIASECCategory: 'Artistic', order: 6 },
    { id: 1022, question: 'Compose or arrange music', RIASECCategory: 'Artistic', order: 17 },
    { id: 1023, question: 'Draw pictures', RIASECCategory: 'Artistic', order: 18 },
    { id: 1024, question: 'Create special effects for movies', RIASECCategory: 'Artistic', order: 29 },
    { id: 1025, question: 'Paint sets for plays', RIASECCategory: 'Artistic', order: 30 },
    { id: 1026, question: 'Write scripts for movies or television shows', RIASECCategory: 'Artistic', order: 41 },
    { id: 1027, question: 'Perform jazz or tap dance', RIASECCategory: 'Artistic', order: 42 },
    { id: 1028, question: 'Sing in a band', RIASECCategory: 'Artistic', order: 53 },
    { id: 1029, question: 'Edit movies', RIASECCategory: 'Artistic', order: 54 },
    { id: 1030, question: 'Teach an individual an exercise routine', RIASECCategory: 'Social', order: 7 },
    { id: 1031, question: 'Help people with personal or emotional problems', RIASECCategory: 'Social', order: 8 },
    { id: 1032, question: 'Give career guidance to people', RIASECCategory: 'Social', order: 19 },
    { id: 1033, question: 'Perform rehabilitation therapy', RIASECCategory: 'Social', order: 20 },
    { id: 1034, question: 'Do volunteer work at a non-profit organization', RIASECCategory: 'Social', order: 31 },
    { id: 1035, question: 'Teach children how to play sports', RIASECCategory: 'Social', order: 32 },
    { id: 1036, question: 'Teach sign language to people who are deaf or hard of hearing', RIASECCategory: 'Social', order: 43 },
    { id: 1037, question: 'Help conduct a group therapy session', RIASECCategory: 'Social', order: 44 },
    { id: 1038, question: 'Take care of children at a day-care center', RIASECCategory: 'Social', order: 55 },
    { id: 1039, question: 'Teach a high-school class', RIASECCategory: 'Social', order: 56 },
    { id: 1040, question: 'Buy and sell stocks and bonds', RIASECCategory: 'Enterprising', order: 9 },
    { id: 1041, question: 'Manage a retail store', RIASECCategory: 'Enterprising', order: 10 },
    { id: 1042, question: 'Operate a beauty salon or barber shop', RIASECCategory: 'Enterprising', order: 21 },
    { id: 1043, question: 'Manage a department within a large company', RIASECCategory: 'Enterprising', order: 22 },
    { id: 1044, question: 'Start your own business', RIASECCategory: 'Enterprising', order: 33 },
    { id: 1045, question: 'Negotiate business contracts', RIASECCategory: 'Enterprising', order: 34 },
    { id: 1046, question: 'Represent a client in a lawsuit', RIASECCategory: 'Enterprising', order: 45 },
    { id: 1047, question: 'Market a new line of clothing', RIASECCategory: 'Enterprising', order: 46 },
    { id: 1048, question: 'Sell merchandise at a department store', RIASECCategory: 'Enterprising', order: 57 },
    { id: 1049, question: 'Manage a clothing store', RIASECCategory: 'Enterprising', order: 58 },
    { id: 1050, question: 'Develop a spreadsheet using computer software', RIASECCategory: 'Conventional', order: 11 },
    { id: 1051, question: 'Proofread records or forms', RIASECCategory: 'Conventional', order: 12 },
    { id: 1052, question: 'Install software across computers on a large network', RIASECCategory: 'Conventional', order: 23 },
    { id: 1053, question: 'Operate a calculator', RIASECCategory: 'Conventional', order: 24 },
    { id: 1054, question: 'Keep shipping and receiving records', RIASECCategory: 'Conventional', order: 35 },
    { id: 1055, question: 'Calculate the wages of employees', RIASECCategory: 'Conventional', order: 36 },
    { id: 1056, question: 'Inventory supplies using a hand-held computer', RIASECCategory: 'Conventional', order: 47 },
    { id: 1057, question: 'Record rent payments', RIASECCategory: 'Conventional', order: 48 },
    { id: 1058, question: 'Keep inventory records', RIASECCategory: 'Conventional', order: 59 },
    { id: 1059, question: 'Stamp, sort, and distribute mail for an organization', RIASECCategory: 'Conventional', order: 60 },

];

export const legacyInterestQuestions: InterestQuestion[] = [
    { id: 1, question: 'Draw cartoons for an animated movie', interestCategory: 'Art', order: 1 },
    { id: 2, question: 'Test water samples for pureness', interestCategory: 'Sci', order: 2 },
    { id: 3, question: 'Grow trees and bushes for sale', interestCategory: 'Pla', order: 3 },
    { id: 4, question: 'Patrol parks and play areas to keep them safe', interestCategory: 'Prt', order: 4 },
    { id: 5, question: 'Design the building of bridges', interestCategory: 'Mch', order: 5 },
    { id: 6, question: 'Tend a machine which mixes dough', interestCategory: 'Ind', order: 6 },
    { id: 7, question: 'File records', interestCategory: 'Bus', order: 7 },
    { id: 8, question: 'Sell insurance', interestCategory: 'Sel', order: 8 },
    { id: 9, question: 'Greet and seat restaurant customers', interestCategory: 'Acc', order: 9 },
    { id: 10, question: 'Teach first aid classes', interestCategory: 'Hum', order: 10 },
    { id: 11, question: 'Be a teacher in a school', interestCategory: 'Ldi', order: 11 },
    { id: 12, question: 'Coach a college basketball team', interestCategory: 'Phy', order: 12 },
    { id: 13, question: 'Repair damaged works of art', interestCategory: 'Art', order: 13 },
    { id: 14, question: 'Study the causes of diseases', interestCategory: 'Sci', order: 14 },
    { id: 15, question: 'Feed and care for dogs and cats in a kennel', interestCategory: 'Pla', order: 15 },
    { id: 16, question: 'Be a prison guard', interestCategory: 'Prt', order: 16 },
    { id: 17, question: 'Design machines that make new products', interestCategory: 'Mch', order: 17 },
    { id: 18, question: 'Sort fabric by color', interestCategory: 'Ind', order: 18 },
    { id: 19, question: `Greet and direct patients in a doctor's office`, interestCategory: 'Bus', order: 19 },
    { id: 20, question: 'Buy products for a chain of stores', interestCategory: 'Sel', order: 20 },
    { id: 21, question: 'Conduct tours of a factory', interestCategory: 'Acc', order: 21 },
    { id: 22, question: 'Help high school students choose a career', interestCategory: 'Hum', order: 22 },
    { id: 23, question: 'Interview people applying for jobs', interestCategory: 'Ldi', order: 23 },
    { id: 24, question: 'Be a sports referee', interestCategory: 'Phy', order: 24 },
    { id: 25, question: 'Write product ads for radio', interestCategory: 'Art', order: 25 },
    { id: 26, question: 'Be a surgeon', interestCategory: 'Sci', order: 26 },
    { id: 27, question: 'Be a dog groomer', interestCategory: 'Pla', order: 27 },
    { id: 28, question: 'Control crowds at a stadium', interestCategory: 'Prt', order: 28 },
    { id: 29, question: 'Direct the repair of tunnels and highways', interestCategory: 'Mch', order: 29 },
    { id: 30, question: 'Set up equipment that makes electronic components', interestCategory: 'Ind', order: 30 },
    { id: 31, question: 'Prepare forms for people applying for loans', interestCategory: 'Bus', order: 31 },
    { id: 32, question: 'Sell household appliances', interestCategory: 'Sel', order: 32 },
    { id: 33, question: 'Be a waiter or waitress', interestCategory: 'Acc', order: 33 },
    { id: 34, question: 'Plan exercise programs to help people with injuries', interestCategory: 'Hum', order: 34 },
    { id: 35, question: 'Do research on customer likes and dislikes', interestCategory: 'Ldi', order: 35 },
    { id: 36, question: 'Be a professional athlete', interestCategory: 'Phy', order: 36 },
    { id: 37, question: 'Design sets for TV shows', interestCategory: 'Art', order: 37 },
    { id: 38, question: 'Test blood samples for diseases', interestCategory: 'Sci', order: 38 },
    { id: 39, question: 'Plant and fertilize crops', interestCategory: 'Pla', order: 39 },
    { id: 40, question: 'Question crime witnesses', interestCategory: 'Prt', order: 40 },
    { id: 41, question: 'Survey land to map building sites', interestCategory: 'Mch', order: 41 },
    { id: 42, question: 'Tend a machine which stamps labels onto toys', interestCategory: 'Ind', order: 42 },
    { id: 43, question: 'Type documents for a lawyer', interestCategory: 'Bus', order: 43 },
    { id: 44, question: 'Help shoppers select products', interestCategory: 'Sel', order: 44 },
    { id: 45, question: 'Give travel information at an airport', interestCategory: 'Acc', order: 45 },
    { id: 46, question: `Visit patients' homes to provide daily health care`, interestCategory: 'Hum', order: 46 },
    { id: 47, question: 'Write job descriptions that list basic work duties', interestCategory: 'Ldi', order: 47 },
    { id: 48, question: 'Coach a professional sports team', interestCategory: 'Phy', order: 48 },
    { id: 49, question: 'Direct actors in a play', interestCategory: 'Art', order: 49 },
    { id: 50, question: 'Study how the earth was formed', interestCategory: 'Sci', order: 50 },
    { id: 51, question: 'Select and prepare food for zoo animals', interestCategory: 'Pla', order: 51 },
    { id: 52, question: 'Rescue people at accident scenes', interestCategory: 'Prt', order: 52 },
    { id: 53, question: 'Install plumbing pipes and fixtures', interestCategory: 'Mch', order: 53 },
    { id: 54, question: 'Unload products and place them on a moving belt', interestCategory: 'Ind', order: 54 },
    { id: 55, question: 'Check billing records for errors', interestCategory: 'Bus', order: 55 },
    { id: 56, question: 'Conduct product demonstrations', interestCategory: 'Sel', order: 56 },
    { id: 57, question: 'Serve people in a fast-food restaurant', interestCategory: 'Acc', order: 57 },
    { id: 58, question: 'Care for children in a day care center', interestCategory: 'Hum', order: 58 },
    { id: 59, question: 'Be a lawyer', interestCategory: 'Ldi', order: 59 },
    { id: 60, question: 'Teach a sport', interestCategory: 'Phy', order: 60 },
    { id: 61, question: 'Direct a musical group', interestCategory: 'Art', order: 61 },
    { id: 62, question: 'Diagnose and treat vision problems', interestCategory: 'Sci', order: 62 },
    { id: 63, question: 'Care for animals in a pet store', interestCategory: 'Pla', order: 63 },
    { id: 64, question: 'Be a store detective', interestCategory: 'Prt', order: 64 },
    { id: 65, question: 'Repair engines', interestCategory: 'Mch', order: 65 },
    { id: 66, question: 'Check the weight of cereal boxes against standards', interestCategory: 'Ind', order: 66 },
    { id: 67, question: 'Figure weekly wages of workers', interestCategory: 'Bus', order: 67 },
    { id: 68, question: 'Visit offices to drop off product brochures', interestCategory: 'Sel', order: 68 },
    { id: 69, question: 'Rent equipment to hotel guests', interestCategory: 'Acc', order: 69 },
    { id: 70, question: 'Give nursing care to hospital patients', interestCategory: 'Hum', order: 70 },
    { id: 71, question: 'Do legal research', interestCategory: 'Ldi', order: 71 },
    { id: 72, question: 'Perform a juggling act in a circus', interestCategory: 'Phy', order: 72 },
    { id: 73, question: 'Compose dance routines for a musical show', interestCategory: 'Art', order: 73 },
    { id: 74, question: 'Be a chemist', interestCategory: 'Sci', order: 74 },
    { id: 75, question: 'Harvest crops', interestCategory: 'Pla', order: 75 },
    { id: 76, question: 'Gather evidence at a crime scene', interestCategory: 'Prt', order: 76 },
    { id: 77, question: 'Build cabinets in homes', interestCategory: 'Mch', order: 77 },
    { id: 78, question: 'Set up a machine which cuts threads on screws', interestCategory: 'Ind', order: 78 },
    { id: 79, question: 'Compute bills', interestCategory: 'Bus', order: 79 },
    { id: 80, question: 'Plan sales contracts', interestCategory: 'Sel', order: 80 },
    { id: 81, question: 'Be an usher at a sports arena', interestCategory: 'Acc', order: 81 },
    { id: 82, question: 'Teach someone to use a man-made arm or leg', interestCategory: 'Hum', order: 82 },
    { id: 83, question: 'Plan systems for improving information flow in a company', interestCategory: 'Ldi', order: 83 },
    { id: 84, question: 'Perform stunts which appear in movies', interestCategory: 'Phy', order: 84 },
    { id: 85, question: 'Design magazine ads', interestCategory: 'Art', order: 85 },
    { id: 86, question: 'Test new drugs for safety', interestCategory: 'Sci', order: 86 },
    { id: 87, question: 'Tend poultry or livestock', interestCategory: 'Pla', order: 87 },
    { id: 88, question: 'Arrest people suspected of crimes', interestCategory: 'Prt', order: 88 },
    { id: 89, question: 'Install or repair wiring', interestCategory: 'Mch', order: 89 },
    { id: 90, question: 'Operate a machine that makes pills', interestCategory: 'Ind', order: 90 },
    { id: 91, question: 'Be a cashier', interestCategory: 'Bus', order: 91 },
    { id: 92, question: 'Arrange to show products to new buyers', interestCategory: 'Sel', order: 92 },
    { id: 93, question: 'Drive a limousine', interestCategory: 'Acc', order: 93 },
    { id: 94, question: 'Help unemployed people enter training programs', interestCategory: 'Hum', order: 94 },
    { id: 95, question: 'Make rules and guidelines for part of a company', interestCategory: 'Ldi', order: 95 },
    { id: 96, question: 'Be a motorcycle racer', interestCategory: 'Phy', order: 96 },
    { id: 97, question: 'Write TV scripts', interestCategory: 'Art', order: 97 },
    { id: 98, question: 'Study cell growth', interestCategory: 'Sci', order: 98 },
    { id: 99, question: 'Care for flowers, plants or trees', interestCategory: 'Pla', order: 99 },
    { id: 100, question: 'Be a bank guard', interestCategory: 'Prt', order: 100 },
    { id: 101, question: 'Control turbines which produce electricity', interestCategory: 'Mch', order: 101 },
    { id: 102, question: 'Fold clothing in a factory', interestCategory: 'Ind', order: 102 },
    { id: 103, question: 'Check printed pages for errors', interestCategory: 'Bus', order: 103 },
    { id: 104, question: 'Explain product uses and costs to people attending an exhibit', interestCategory: 'Sel', order: 104 },
    { id: 105, question: 'Arrange and record dinner reservations', interestCategory: 'Acc', order: 105 },
    { id: 106, question: 'Take care of infants in a nursery', interestCategory: 'Hum', order: 106 },
    { id: 107, question: 'Give advice on investing money', interestCategory: 'Ldi', order: 107 },
    { id: 108, question: 'Be a track and field coach', interestCategory: 'Phy', order: 108 },
    { id: 109, question: 'Take pictures of events for a newspaper', interestCategory: 'Art', order: 109 },
    { id: 110, question: 'Design ways to control plant diseases', interestCategory: 'Sci', order: 110 },
    { id: 111, question: 'Direct workers on a dairy farm', interestCategory: 'Pla', order: 111 },
    { id: 112, question: 'Investigate crime suspects', interestCategory: 'Prt', order: 112 },
    { id: 113, question: 'Install household appliances', interestCategory: 'Mch', order: 113 },
    { id: 114, question: 'Check the thickness of glass windows as they are produced', interestCategory: 'Ind', order: 114 },
    { id: 115, question: 'Sort mail to be sent to different departments', interestCategory: 'Bus', order: 115 },
    { id: 116, question: 'Attend a training course on selling', interestCategory: 'Sel', order: 116 },
    { id: 117, question: 'Sell food items at a sports stadium', interestCategory: 'Acc', order: 117 },
    { id: 118, question: 'Plan social events in a nursing home', interestCategory: 'Hum', order: 118 },
    { id: 119, question: 'Direct a nationwide labor statistics program', interestCategory: 'Ldi', order: 119 },
    { id: 120, question: 'Perform dare-devil acts at car races', interestCategory: 'Phy', order: 120 },
    { id: 121, question: 'Teach drama classes', interestCategory: 'Art', order: 121 },
    { id: 122, question: 'Study the atmosphere of planets', interestCategory: 'Sci', order: 122 },
    { id: 123, question: 'Pack farm produce', interestCategory: 'Pla', order: 123 },
    { id: 124, question: 'Guard works of art in a museum', interestCategory: 'Prt', order: 124 },
    { id: 125, question: 'Sew labels onto clothing', interestCategory: 'Ind', order: 125 },
    { id: 126, question: 'Take and record messages for a doctor', interestCategory: 'Bus', order: 126 },
    { id: 127, question: 'Help people who have emotional problems', interestCategory: 'Hum', order: 127 },
    { id: 128, question: 'Plan special programs for a school district', interestCategory: 'Ldi', order: 128 },
    { id: 129, question: 'Be a sculptor', interestCategory: 'Art', order: 129 },
    { id: 130, question: 'Do research on nutrition', interestCategory: 'Sci', order: 130 },
    { id: 131, question: 'Teach new police officers how to investigate crimes', interestCategory: 'Prt', order: 131 },
    { id: 132, question: 'Install drywall in buildings', interestCategory: 'Mch', order: 132 },
    { id: 133, question: 'Operate a machine that uses lasers to weld metal parts', interestCategory: 'Ind', order: 133 },
    { id: 134, question: 'Maintain bank and account records for a small business', interestCategory: 'Bus', order: 134 },
    { id: 135, question: 'Direct a fund raising drive for a large college', interestCategory: 'Ldi', order: 135 },
    { id: 136, question: 'Paint and glaze pottery', interestCategory: 'Art', order: 136 },
    { id: 137, question: 'Diagnose and treat diseases of the blood', interestCategory: 'Sci', order: 137 },
    { id: 138, question: 'Purchase equipment for a business', interestCategory: 'Ldi', order: 138 },
    { id: 139, question: 'Compose music for a TV program', interestCategory: 'Art', order: 139 },
    { id: 140, question: 'Operate heavy tractors and cranes', interestCategory: 'Mch', order: 140 },
    { id: 141, question: 'Help to manage income and spending for a company', interestCategory: 'Ldi', order: 141 },
];


// Modernized version of interest inventory
// Updated 9/12/22
export const interestQuestions: InterestQuestion[] = [
    { id: 1, question: `Create graphic designs`, interestCategory: `Art`, order: 1 },
    { id: 2, question: `Test the purity of water samples`, interestCategory: `Sci`, order: 2 },
    { id: 3, question: `Grow trees on a tree farm`, interestCategory: `Pla`, order: 3 },
    { id: 4, question: `Patrol parks and play areas to keep them safe`, interestCategory: `Prt`, order: 4 },
    { id: 5, question: `Design the building of bridges`, interestCategory: `Mch`, order: 5 },
    { id: 6, question: `Control a machine which recycles plastic`, interestCategory: `Ind`, order: 6 },
    { id: 7, question: `Organize documents`, interestCategory: `Bus`, order: 7 },
    { id: 8, question: `Sell insurance`, interestCategory: `Sel`, order: 8 },
    { id: 9, question: `Greet and seat restaurant customers`, interestCategory: `Acc`, order: 9 },
    { id: 10, question: `Teach first aid classes`, interestCategory: `Hum`, order: 10 },
    { id: 11, question: `Teach students in a school`, interestCategory: `Ldi`, order: 11 },
    { id: 12, question: `Coach a college basketball team`, interestCategory: `Phy`, order: 12 },
    { id: 13, question: `Restore damaged works of art`, interestCategory: `Art`, order: 13 },
    { id: 14, question: `Investigate the cause of diseases`, interestCategory: `Sci`, order: 14 },
    { id: 15, question: `Feed and care for dogs and cats in a kennel`, interestCategory: `Pla`, order: 15 },
    { id: 16, question: `Guard people in a prison`, interestCategory: `Prt`, order: 16 },
    { id: 17, question: `Design machines that make new products`, interestCategory: `Mch`, order: 17 },
    { id: 18, question: `Sort fabric by color`, interestCategory: `Ind`, order: 18 },
    { id: 19, question: `Register patients in a medical office`, interestCategory: `Bus`, order: 19 },
    { id: 20, question: `Buy products for a chain of stores`, interestCategory: `Sel`, order: 20 },
    { id: 21, question: `Conduct tours of city landmarks`, interestCategory: `Acc`, order: 21 },
    { id: 22, question: `Give career guidance to students`, interestCategory: `Hum`, order: 22 },
    { id: 23, question: `Interview people applying for jobs`, interestCategory: `Ldi`, order: 23 },
    { id: 24, question: `Referee a sporting event`, interestCategory: `Phy`, order: 24 },
    { id: 25, question: `Write product ads for podcasts`, interestCategory: `Art`, order: 25 },
    { id: 26, question: `Perform surgery on patients`, interestCategory: `Sci`, order: 26 },
    { id: 27, question: `Groom dogs for a dog show`, interestCategory: `Pla`, order: 27 },
    { id: 28, question: `Control crowds at a stadium`, interestCategory: `Prt`, order: 28 },
    { id: 29, question: `Direct the repair of tunnels and highways`, interestCategory: `Mch`, order: 29 },
    { id: 30, question: `Set up machines in a factory`, interestCategory: `Ind`, order: 30 },
    { id: 31, question: `Prepare loan applications`, interestCategory: `Bus`, order: 31 },
    { id: 32, question: `Help buyers select household products`, interestCategory: `Sel`, order: 32 },
    { id: 33, question: `Serve customers in a restaurant`, interestCategory: `Acc`, order: 33 },
    { id: 34, question: `Work with patients to improve their physical mobility`, interestCategory: `Hum`, order: 34 },
    { id: 35, question: `Collect product feedback from customers`, interestCategory: `Ldi`, order: 35 },
    { id: 36, question: `Play a professional sport`, interestCategory: `Phy`, order: 36 },
    { id: 37, question: `Design movie sets`, interestCategory: `Art`, order: 37 },
    { id: 38, question: `Examine blood samples using a microscope`, interestCategory: `Sci`, order: 38 },
    { id: 39, question: `Plant and fertilize crops`, interestCategory: `Pla`, order: 39 },
    { id: 40, question: `Question crime witnesses`, interestCategory: `Prt`, order: 40 },
    { id: 41, question: `Survey land to map building sites`, interestCategory: `Mch`, order: 41 },
    { id: 42, question: `Monitor a machine which attaches labels to boxes`, interestCategory: `Ind`, order: 42 },
    { id: 43, question: `Type documents for a lawyer`, interestCategory: `Bus`, order: 43 },
    { id: 44, question: `Advise people on choosing a mobile phone`, interestCategory: `Sel`, order: 44 },
    { id: 45, question: `Assist travelers at an airport`, interestCategory: `Acc`, order: 45 },
    { id: 46, question: `Visit patients' homes to provide daily health care`, interestCategory: `Hum`, order: 46 },
    { id: 47, question: `Write job descriptions that list basic work duties`, interestCategory: `Ldi`, order: 47 },
    { id: 48, question: `Coach a professional athlete`, interestCategory: `Phy`, order: 48 },
    { id: 49, question: `Direct a play`, interestCategory: `Art`, order: 49 },
    { id: 50, question: `Study the movement of planets`, interestCategory: `Sci`, order: 50 },
    { id: 51, question: `Prepare food for zoo animals`, interestCategory: `Pla`, order: 51 },
    { id: 52, question: `Rescue people at accident scenes`, interestCategory: `Prt`, order: 52 },
    { id: 53, question: `Install plumbing pipes and fixtures`, interestCategory: `Mch`, order: 53 },
    { id: 54, question: `Unload products onto a moving belt`, interestCategory: `Ind`, order: 54 },
    { id: 55, question: `Check billing records for errors`, interestCategory: `Bus`, order: 55 },
    { id: 56, question: `Demonstrate products to shoppers`, interestCategory: `Sel`, order: 56 },
    { id: 57, question: `Take customer orders in a fast food restaurant`, interestCategory: `Acc`, order: 57 },
    { id: 58, question: `Provide art therapy sessions`, interestCategory: `Hum`, order: 58 },
    { id: 59, question: `Present a legal case in court`, interestCategory: `Ldi`, order: 59 },
    { id: 60, question: `Teach children how to play sports`, interestCategory: `Phy`, order: 60 },
    { id: 61, question: `Conduct a choir`, interestCategory: `Art`, order: 61 },
    { id: 62, question: `Diagnose and treat vision problems`, interestCategory: `Sci`, order: 62 },
    { id: 63, question: `Care for animals in a pet store`, interestCategory: `Pla`, order: 63 },
    { id: 64, question: `Prevent theft in stores`, interestCategory: `Prt`, order: 64 },
    { id: 65, question: `Repair engines`, interestCategory: `Mch`, order: 65 },
    { id: 66, question: `Inspect products for quality before they are packaged`, interestCategory: `Ind`, order: 66 },
    { id: 67, question: `Calculate the wages of employees`, interestCategory: `Bus`, order: 67 },
    { id: 68, question: `Provide product samples to businesses`, interestCategory: `Sel`, order: 68 },
    { id: 69, question: `Check in guests at a hotel `, interestCategory: `Acc`, order: 69 },
    { id: 70, question: `Give nursing care to hospital patients`, interestCategory: `Hum`, order: 70 },
    { id: 71, question: `Do legal research`, interestCategory: `Ldi`, order: 71 },
    { id: 72, question: `Perform acrobatic stunts in a show`, interestCategory: `Phy`, order: 72 },
    { id: 73, question: `Arrange dance routines`, interestCategory: `Art`, order: 73 },
    { id: 74, question: `Conduct chemical experiments`, interestCategory: `Sci`, order: 74 },
    { id: 75, question: `Harvest crops`, interestCategory: `Pla`, order: 75 },
    { id: 76, question: `Gather evidence at a crime scene`, interestCategory: `Prt`, order: 76 },
    { id: 77, question: `Build cabinets in homes`, interestCategory: `Mch`, order: 77 },
    { id: 78, question: `Set up a machine which cuts threads on screws`, interestCategory: `Ind`, order: 78 },
    { id: 79, question: `Prepare billing statements`, interestCategory: `Bus`, order: 79 },
    { id: 80, question: `Plan sales contracts`, interestCategory: `Sel`, order: 80 },
    { id: 81, question: `Usher audience members to their seats`, interestCategory: `Acc`, order: 81 },
    { id: 82, question: `Teach someone to use an artificial limb`, interestCategory: `Hum`, order: 82 },
    { id: 83, question: `Plan systems for improving information flow in a company`, interestCategory: `Ldi`, order: 83 },
    { id: 84, question: `Perform movie stunts`, interestCategory: `Phy`, order: 84 },
    { id: 85, question: `Design social media ads`, interestCategory: `Art`, order: 85 },
    { id: 86, question: `Test a new vaccine`, interestCategory: `Sci`, order: 86 },
    { id: 87, question: `Care for animals on a farm`, interestCategory: `Pla`, order: 87 },
    { id: 88, question: `Arrest people suspected of crimes`, interestCategory: `Prt`, order: 88 },
    { id: 89, question: `Repair and install locks`, interestCategory: `Mch`, order: 89 },
    { id: 90, question: `Operate a machine that makes paper`, interestCategory: `Ind`, order: 90 },
    { id: 91, question: `Scan items for checkout in a store`, interestCategory: `Bus`, order: 91 },
    { id: 92, question: `Arrange to show houses to new buyers`, interestCategory: `Sel`, order: 92 },
    { id: 93, question: `Drive people to airports, hotels, and events`, interestCategory: `Acc`, order: 93 },
    { id: 94, question: `Help people enter job training programs`, interestCategory: `Hum`, order: 94 },
    { id: 95, question: `Create security programs to protect computer networks`, interestCategory: `Ldi`, order: 95 },
    { id: 96, question: `Compete in motorcycle races`, interestCategory: `Phy`, order: 96 },
    { id: 97, question: `Write scripts for movies`, interestCategory: `Art`, order: 97 },
    { id: 98, question: `Study cell growth`, interestCategory: `Sci`, order: 98 },
    { id: 99, question: `Care for flowers, plants or trees`, interestCategory: `Pla`, order: 99 },
    { id: 100, question: `Guard money at a bank`, interestCategory: `Prt`, order: 100 },
    { id: 101, question: `Control turbines which produce electricity`, interestCategory: `Mch`, order: 101 },
    { id: 102, question: `Fold clothing in a factory`, interestCategory: `Ind`, order: 102 },
    { id: 103, question: `Enter data into spreadsheets`, interestCategory: `Bus`, order: 103 },
    { id: 104, question: `Explain product uses and costs to people attending a convention`, interestCategory: `Sel`, order: 104 },
    { id: 105, question: `Manage restaurant reservations`, interestCategory: `Acc`, order: 105 },
    { id: 106, question: `Take care of infants in a nursery`, interestCategory: `Hum`, order: 106 },
    { id: 107, question: `Give advice on investing money`, interestCategory: `Ldi`, order: 107 },
    { id: 108, question: `Coach track and field events`, interestCategory: `Phy`, order: 108 },
    { id: 109, question: `Film events for news stories`, interestCategory: `Art`, order: 109 },
    { id: 110, question: `Design ways to control plant diseases`, interestCategory: `Sci`, order: 110 },
    { id: 111, question: `Direct workers on a dairy farm`, interestCategory: `Pla`, order: 111 },
    { id: 112, question: `Investigate crime suspects`, interestCategory: `Prt`, order: 112 },
    { id: 113, question: `Repair household appliances`, interestCategory: `Mch`, order: 113 },
    { id: 114, question: `Check the thickness of metal sheets as they are produced`, interestCategory: `Ind`, order: 114 },
    { id: 115, question: `Sort mail to be sent to different departments`, interestCategory: `Bus`, order: 115 },
    { id: 116, question: `Take an online training course on marketing and sales`, interestCategory: `Sel`, order: 116 },
    { id: 117, question: `Cut and style a client's hair`, interestCategory: `Acc`, order: 117 },
    { id: 118, question: `Plan social events at a senior center`, interestCategory: `Hum`, order: 118 },
    { id: 119, question: `Conduct investigations on cyber crimes`, interestCategory: `Ldi`, order: 119 },
    { id: 120, question: `Perform daredevil acts at car races`, interestCategory: `Phy`, order: 120 },
    { id: 121, question: `Teach drama classes`, interestCategory: `Art`, order: 121 },
    { id: 122, question: `Study the atmosphere of planets`, interestCategory: `Sci`, order: 122 },
    { id: 123, question: `Package fruits and vegetables from a farm`, interestCategory: `Pla`, order: 123 },
    { id: 124, question: `Guard works of art in a museum`, interestCategory: `Prt`, order: 124 },
    { id: 125, question: `Package products for shipping from a warehouse`, interestCategory: `Ind`, order: 125 },
    { id: 126, question: `Answer and transfer calls for a business`, interestCategory: `Bus`, order: 126 },
    { id: 127, question: `Counsel people seeking mental health support`, interestCategory: `Hum`, order: 127 },
    { id: 128, question: `Plan programs for a school district`, interestCategory: `Ldi`, order: 128 },
    { id: 129, question: `Sculpt materials such as clay, wood, or stone`, interestCategory: `Art`, order: 129 },
    { id: 130, question: `Research the nutritional value of plant-based foods`, interestCategory: `Sci`, order: 130 },
    { id: 131, question: `Teach new police officers how to investigate crimes`, interestCategory: `Prt`, order: 131 },
    { id: 132, question: `Install drywall in buildings`, interestCategory: `Mch`, order: 132 },
    { id: 133, question: `Operate a machine that uses lasers to weld metal parts`, interestCategory: `Ind`, order: 133 },
    { id: 134, question: `Maintain financial records for a small business`, interestCategory: `Bus`, order: 134 },
    { id: 135, question: `Direct a fundraising drive for a large college`, interestCategory: `Ldi`, order: 135 },
    { id: 136, question: `Paint and glaze pottery`, interestCategory: `Art`, order: 136 },
    { id: 137, question: `Diagnose and treat cancers`, interestCategory: `Sci`, order: 137 },
    { id: 138, question: `Purchase equipment for a business`, interestCategory: `Ldi`, order: 138 },
    { id: 139, question: `Compose music`, interestCategory: `Art`, order: 139 },
    { id: 140, question: `Operate heavy tractors and cranes`, interestCategory: `Mch`, order: 140 },
    { id: 141, question: `Help manage finances for a company`, interestCategory: `Ldi`, order: 141 },
];
