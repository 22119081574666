import { inject } from '@angular/core';
import { EvalueeService } from '../_services/evaluee.service';
import { filter, map, of, switchMap, take, tap } from 'rxjs';
import { AssessmentService } from '../_services/assessment.service';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';

export const assessmentGuard = () => {
  const es = inject(EvalueeService);
  const auth = inject(AuthService);
  const router = inject(Router);
  const assessmentService = inject(AssessmentService);

  return es.evaluee.pipe(
    filter(evaluee => evaluee !== null),
    take(1),
    switchMap(evaluee => {
      if (!evaluee) {
        signOutRedirectToLogin(auth, router);
        return of(false);
      }

      if (!evaluee.currentAssessmentId) {
        auth.serverMessage.next('No assessment found for evaluee: ' + evaluee.uid + '.  Please send this message to your counselor.');
        signOutRedirectToLogin(auth, router);
        return of(false);
      }

      assessmentService.setAssessmentObservable(evaluee.portalId, evaluee.uid, evaluee.currentAssessmentId);

      return assessmentService.assessment.pipe(
        filter(assessment => assessment !== null),
        take(1),
        map(assessment => !!assessment),
        tap(assessment => {
          if (!assessment) {
            console.log('Assessment', evaluee.currentAssessmentId, 'not found for evaluee:', evaluee.uid);
            auth.serverMessage.next('Assessment ' + evaluee.currentAssessmentId + ' not found for evaluee: ' + evaluee.uid + '.  Please send this message to your counselor.');
            signOutRedirectToLogin(auth, router);
          }
        })
      );
    })
  );
}

function signOutRedirectToLogin(auth: AuthService, router: Router) {
  auth.signOutNoRedirect();
  router.navigate(['/login']);
}