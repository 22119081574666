import { OccupationFilter } from '@career-scope/models';

export const JobZoneColorMap = ['#F2F2F2','#FF9999','#23E8AA','#23BAE8','#4D64DF','#AD2DDF'];

export const emptyOccupationFilter: OccupationFilter = {
  search: '',
  interests: [],
  jobZones: [],
  jobFamilies: [],
  showHiddenJobs: false,
  min: null,
  max: null,
  evalueeAptitudes: [],
  aptitudes: []
}
