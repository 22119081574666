import { Injectable } from '@angular/core';
import { EvalueeActivityEnum, EvalueeActivity } from '../_models/evaluee-activity.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Evaluee, EvalueeJobInfo, OccupationFilter } from '@career-scope/models';
import { emptyOccupationFilter } from '../_models/job-result.model';
import { addDoc, collection, doc, Firestore, setDoc } from '@angular/fire/firestore';


// TODO: We are passing around the full evaluee object.  Refactor to only send the necessary data

@Injectable({
    providedIn: 'root'
})
export class EvalueePathService {
    evaluee: BehaviorSubject<Evaluee | null> = new BehaviorSubject<Evaluee | null>(null);
    evalueeJobInfo: BehaviorSubject<EvalueeJobInfo[]> = new BehaviorSubject<EvalueeJobInfo[]>([]);
    evalueeOccupationFilters: BehaviorSubject<OccupationFilter> = new BehaviorSubject<OccupationFilter>(emptyOccupationFilter);

    constructor(
        private firestore: Firestore,
    ) { }

    // Called from Evaluee Service when an evaluee is loaded/refreshed from the database
    updateEvalueePathInfo(evaluee: Evaluee) {
        this.evaluee.next(evaluee);
        this.evalueeJobInfo.next(evaluee.evalueeJobInfo || []);
        this.evalueeOccupationFilters.next(evaluee.occupationFilters || emptyOccupationFilter);
    }

    // Called from Evaluee Service when an evaluee is cleared
    clearEvalueePathInfo() {
        this.evaluee.next(null);
        this.evalueeJobInfo.next([]);
        this.evalueeOccupationFilters.next(emptyOccupationFilter);
    }

    // ! Copied from Evaluee Service.  These will be stored in a different location in the future
    // We should then only pass the information needed and not the entire evaluee object
    private saveEvaluee(evaluee: Evaluee) {
        // We use last modified date to determine which records need to be updated in our Evaluee Sets Cached Arrays for Evaluee listings
        evaluee.lastModifiedDate = new Date(Date.now());

        const userRef = doc(this.firestore, `portals/${evaluee.portalId}/evaluees/${evaluee.uid}`);

        // Error can't be caught here, firebase will keep attempting to save
        setDoc(userRef, evaluee, { merge: true });
    }

    bookmarkOccupation(onetNumber: string, occupation: string, bookmark: boolean) {
        const evaluee = this.evaluee.value;
        if (evaluee) {
            const found = this.evalueeJobInfo.value.find(job => job.onetNumber === onetNumber);

            const evalueeJobInfo = found ?
                this.evalueeJobInfo.value.map(job => job.onetNumber === onetNumber ? { ...job, saved: bookmark } : job) :
                [...this.evalueeJobInfo.value, { onetNumber, matchPercent: null, saved: bookmark }];

            this.saveEvaluee({ ...evaluee, evalueeJobInfo });

            const activity = {
                date: new Date(Date.now()),
                action: bookmark ? EvalueeActivityEnum.SAVE_OCCUPATION : EvalueeActivityEnum.UNSAVE_OCCUPATION,
                data: {
                    occupation,
                    onetNumber
                }
            };

            this.saveActivityToStream(activity);
        }
    }

    hideOccupation(onetNumber: string, occupation: string, hide: boolean, reason: string | null) {
        const evaluee = this.evaluee.value;
        if (evaluee) {
            const found = this.evalueeJobInfo.value.find(job => job.onetNumber === onetNumber);

            const evalueeJobInfo = found ?
                this.evalueeJobInfo.value.map(job => job.onetNumber === onetNumber ? { ...job, hidden: hide, hiddenReason: reason } : job) :
                [...this.evalueeJobInfo.value, { onetNumber, matchPercent: null, hidden: hide, saved: false, hiddenReason: reason }];

            this.saveEvaluee({ ...evaluee, evalueeJobInfo });

            const activity = {
                date: new Date(Date.now()),
                action: hide ? EvalueeActivityEnum.HIDE_OCCUPATION : EvalueeActivityEnum.SHOW_OCCUPATION,
                data: {
                    occupation,
                    onetNumber,
                    reason
                }
            };

            this.saveActivityToStream(activity);
        }
    }

    getEvalueeJobInfo(onetNumber: string): Observable<EvalueeJobInfo> {
        const jobInfo = this.evalueeJobInfo.value.find(job => job.onetNumber === onetNumber);

        if (jobInfo) {
            return of(jobInfo);
        }

        return of({
            onetNumber,
            matchPercent: null,
            saved: false,
            hidden: false
        });
    }

    setEvalueeOccupationFilters(filters: OccupationFilter) {
        this.evalueeOccupationFilters.next(filters);
    }

    saveOccupationFilters() {
        const evaluee = this.evaluee.value;
        const occupationFilters = this.evalueeOccupationFilters.value;
        if (evaluee && occupationFilters) {
            this.saveEvaluee({ ...evaluee, occupationFilters: occupationFilters });
        }
    }

    saveActivityToStream(activity: EvalueeActivity) {
        const evaluee = this.evaluee.value;
        if (evaluee) {
            const { portalId, uid } = evaluee;

            const userRef = doc(this.firestore, `portals/${portalId}/evaluees/${uid}`);
            const activityRef = collection(userRef, 'activityStream');

            addDoc(activityRef, activity);
        }
    }

}