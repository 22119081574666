import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { AudioService } from './_services/audio.service';
import { AuthService } from './_services/auth.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ShellComponent } from './shared/shell/shell.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [ShellComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'CareerScope';
  showAppShell = false;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private router: Router,
    private as: AuthService,
    private audio: AudioService
  ) {
    this.matIconRegistry.addSvgIcon(
      'btn_start',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Btn-start.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'btn_check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Btn-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'solid_check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/solid-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'duration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/Recent.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/check-circle.svg')
    );


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateAppShellVisibility(this.router.url);
      }
    });
  }

  updateAppShellVisibility(url: string): void {
    // TODO: Update urls
    this.showAppShell = !url?.includes('login') && !url?.includes('portal') && !url?.includes('username');
  }

  scrollTopNavContent(): void {
    const sideNav = document.querySelector('.mat-sidenav-content');

    if (sideNav) {
      sideNav.scrollTop = 0;
    }
  }



  ngOnInit() {
    this.titleService.setTitle('CareerScope');
  }
}
