import { Injectable, LOCALE_ID, inject, signal } from '@angular/core';
import { AptitudeScore, AptitudeTask, Assessment, Evaluee, EvalueeAnswer, InterestResult, InterestScore, TaskResult } from '@career-scope/models';
import { BehaviorSubject, Observable, Subscription, of, tap } from 'rxjs';
import { InterestService } from './interest.service';
import { FeaturesSecurityService } from './features-security.service';
import { AptitudeService } from './aptitude.service';
import { doc, docData, DocumentReference, Firestore, updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  firestore = inject(Firestore);
  is = inject(InterestService);
  as = inject(AptitudeService);
  fss = inject(FeaturesSecurityService);
  locale = inject(LOCALE_ID);

  assessment = new BehaviorSubject<Assessment | null | undefined>(null);
  assessment$: Observable<Assessment | undefined> = of(undefined);
  assessmentSubscription: Subscription | null = null;

  // TODO: Interest inventory progress for UI

  calculatingResults = signal(false);

  answersLoaded = false;

  constructor() {
    this.handleAssessmentChanges();
  }

  setAssessmentObservable(portalId: string, evalueeId: string, assessmentId: string) {
    const assessment = this.assessment.value;

    if (assessment && assessment.id === assessmentId) {
      return;
    }

    const assessmentDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}/assessments/${assessmentId}`) as DocumentReference<Assessment>;
    this.assessment$ = docData(assessmentDocRef).pipe(
      tap((assessment) => {
        if (!assessment) {
          this.assessment.next(undefined);
          return;
        }
        this.assessment.next(assessment);
      })
    );

    this.assessmentSubscription = this.assessment$.pipe(
      tap(assessment => this.assessment.next(assessment))
    ).subscribe();
  }

  private handleAssessmentChanges(): void {
    this.assessment.pipe(
      tap(assessment => {
        if (!assessment) {
          this.as.resetExercisesAndAnswers();
          this.is.resetInterestInventoryAnswers();
          return;
        }

        if (!this.answersLoaded && assessment.evalueeAnswers.length > 0) {
          this.is.setInterestInventoryAnswers(assessment.evalueeAnswers);
          this.loadExerciseComplete(assessment.taskResults);
          this.answersLoaded = true;
        }

        // If the assessment is complete and the scores are not calculated then we are letting the user know we are calculating the scores
        if (assessment.exercisesComplete && assessment.interestsComplete) {
          const calculationFunctionRunning = assessment.aptitudeScores.length === 0 && assessment.interestScores.length === 0;
          this.calculatingResults.set(calculationFunctionRunning);
        }

        this.is.setTopInterests(assessment.topInterestScores || []);
      })
    ).subscribe();
  }

  private loadExerciseComplete(taskResults: TaskResult[]) {
    const completedExercises = taskResults.filter(result => result.completedDate);
    completedExercises.map(result => this.as.markTaskComplete(result.taskId));
  }

  completeExercisesHQ() {
    this.as.completeExercisesHQ();

    const assessment = this.assessment.value;

    if (!assessment) {
      return;
    }

    let evalueeAnswers = assessment.evalueeAnswers;
    let taskResults: TaskResult[] = [];

    this.as.getTasks()().map(task => {
      const filteredAnswers = evalueeAnswers.filter(answer => answer.taskId !== task.id);
      const answers = this.as.getAnswersByAptitudeTask(task.id);
      const result = this.updateTaskResults(assessment.taskResults, task.id, task.timeOutLength);

      evalueeAnswers = [...filteredAnswers, ...answers];
      taskResults = [...result];
      this.as.markTaskComplete(task.id);
    });

    const exercisesComplete = new Date(Date.now());
    const lastActivity = 'Exercises Completed';
    const lastActivityDate = new Date(Date.now());
    const exerciseStatus = '7/7 tasks completed';

    this.saveAssessment({ evalueeAnswers, taskResults, exercisesComplete }, assessment.portalId, assessment.evalueeId, assessment.id);
    this.saveEvaluee({ exercisesComplete, lastActivity, lastActivityDate, exerciseStatus }, assessment.portalId, assessment.evalueeId);
  }

  completeInterestsHQ() {
    this.is.completeInterestQuestions();
    this.saveInterestAnswers();
  }

  resetAnswersHQ() {
    const assessment = this.assessment.value;

    if (!assessment) {
      return;
    }

    this.saveAssessment({ evalueeAnswers: [], taskResults: [], aptitudeScores: [], interestResults: [], interestScores: [], interestsComplete: null, exercisesComplete: null, completedDate: null }, assessment.portalId, assessment.evalueeId, assessment.id);
    this.saveEvaluee({ interestComplete: null, exercisesComplete: null, lastActivity: 'answers reset', lastActivityDate: new Date(Date.now()), interestStatus: '', exerciseStatus: '', status: 'in progress' }, assessment.portalId, assessment.evalueeId);

    this.as.resetExercisesAndAnswers();
    this.is.resetInterestInventoryAnswers();
  }

  saveInterestAnswers() {
    const assessment = this.assessment.value;

    if (!assessment) {
      return;
    }

    const filteredAnswers = assessment.evalueeAnswers.filter(answer => answer.taskId !== this.is.INTEREST_INVENTORY_TASK_ID);
    const interestAnswers = this.is.getInterestInventoryAnswers();

    const evalueeAnswers = [...filteredAnswers, ...interestAnswers];

    const interestCompletedPercent = Math.round(interestAnswers.length / this.is.getInterestQuestions()().length * 100);

    const interestsComplete = this.is.getInterestQuestions()().length === interestAnswers.length ? new Date(Date.now()) : null;
    const lastActivity = interestsComplete ? 'Interest Completed' : 'Interest ' + interestCompletedPercent + '% complete';
    const interestStatus = interestCompletedPercent + '%';
    const lastActivityDate = new Date(Date.now());
    const interestResults: InterestResult[] = [];
    const interestScores: InterestScore[] = [];
    const topInterestScores: InterestScore[] = [];

    this.saveAssessment({ evalueeAnswers, interestResults, interestsComplete, interestScores, topInterestScores }, assessment.portalId, assessment.evalueeId, assessment.id);

    this.saveEvaluee({ interestComplete: interestsComplete, lastActivity, lastActivityDate, interestStatus }, assessment.portalId, assessment.evalueeId);
  }

  saveAptitudeTask(task: AptitudeTask, timeToComplete: number, screenWidth?: number, screenHeight?: number) {
    const assessment = this.assessment.value;

    if (!assessment) {
      return; // TODO: error??
    }

    const taskResults = this.updateTaskResults(assessment.taskResults, task.id, timeToComplete, screenWidth, screenHeight);
    const evalueeAnswers = this.updateAptitudeAnswers(assessment.evalueeAnswers, task.id);

    const lastActivity = task.name + ' completed';
    const lastActivityDate = new Date(Date.now());

    this.as.markTaskComplete(task.id);

    const completedTaskCount = this.as.getCompletedTaskCount();

    const exerciseStatus = completedTaskCount + '/' + this.as.totalTasks + ' tasks completed';
    const exercisesComplete = completedTaskCount === this.as.totalTasks ? new Date(Date.now()) : null;
    const aptitudeScores: AptitudeScore[] = [];

    this.saveAssessment({ taskResults, evalueeAnswers, exercisesComplete, aptitudeScores }, assessment.portalId, assessment.evalueeId, assessment.id);
    this.saveEvaluee({ exercisesComplete, lastActivity, lastActivityDate, exerciseStatus }, assessment.portalId, assessment.evalueeId);
  }

  private updateTaskResults(taskResults: TaskResult[], taskId: number, timeToComplete: number, screenWidth?: number, screenHeight?: number): TaskResult[] {
    const taskResult: TaskResult = {
      taskId,
      timeToComplete: Math.floor(timeToComplete),
      screenWidth: screenWidth || null,
      screenHeight: screenHeight || null,
      completedDate: new Date(Date.now()),
      language: this.locale === 'en' ? 'English' : 'Spanish',
      audioEnabled: this.fss.getAudioEnabled().value,
      exerciseTimeModifier: this.getExerciseTimeModifierSaveValue(this.fss.getExerciseTimeModifier().value),
      correct: null,
      incorrect: null,
      unanswered: null
    };

    const taskResultIndex = taskResults.findIndex(result => result.taskId === taskId);

    if (taskResultIndex === -1) {
      taskResults.push(taskResult);
    } else {
      taskResults[taskResultIndex] = taskResult;
    }

    return taskResults;
  }

  private getExerciseTimeModifierSaveValue(value: number) {
    if (!value || value === 1) {
      return null;
    }
    return value;

  }

  private updateAptitudeAnswers(answers: EvalueeAnswer[], taskId: number): EvalueeAnswer[] {
    const filteredAnswers = answers.filter(answer => answer.taskId !== taskId);
    const aptitudeAnswers = this.as.getAnswersByAptitudeTask(taskId);

    return [...filteredAnswers, ...aptitudeAnswers];
  }

  private saveEvaluee(evaluee: Partial<Evaluee>, portalId: string, evalueeId: string) {
    // We use last modified date to determine which records need to be updated in our Evaluee Sets Cached Arrays for Evaluee listings
    evaluee.lastModifiedDate = new Date(Date.now());

    const evalueeRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}`) as DocumentReference<Evaluee>;

    // Error can't be caught here, firebase will keep attempting to save
    updateDoc(evalueeRef, evaluee);
  }

  saveAssessment(assessment: Partial<Assessment>, portalId: string, evalueeId: string, assessmentId: string) {
    const assessmentRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}/assessments/${assessmentId}`) as DocumentReference<Assessment>;
    updateDoc(assessmentRef, { ...assessment, lastModifiedDate: new Date(Date.now()) });
  }

  setAssessmentNullAndUnsubscribe() {
    this.assessment.next(null);
    this.answersLoaded = false;
    this.assessmentSubscription?.unsubscribe();
  }
}