import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { take, of, switchMap, map, tap, filter } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { EvalueeService } from '../_services/evaluee.service';

export const authGuard = () => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const es = inject(EvalueeService);

  return auth.user$.pipe(
    take(1), // May be unnecessary, it always takes 1
    // user is from our User Collection in Firestore (not the firebase auth user object))
    switchMap(user => {
      if (!user || !user.portalId) {
        console.log('access denied');
        signOutRedirectToLogin(auth, router);
        return of(false);
      }

      // Only attempt to load evaluee if the user is not the same as the evaluee
      // This would occur on login or when the user refreshes the page or directly navigates to a page via URL1
      es.setEvalueeObservable(user.portalId, user.evalueeId || user.uid);

      // return es.loadEvaluee(user.portalId, user.uid);
      return es.evaluee.pipe(
        filter(evaluee => evaluee !== null),
        take(1),
        map(evaluee => !!evaluee),
        tap(evaluee => {
          if (!evaluee) {
            console.log('Evaluee', user.uid, 'not found for portal:', user.portalId);
            auth.serverMessage.next('Evaluee ' + user.uid + ' not found for portal: ' + user.portalId + '.  Please send this message to your counselor.');
            signOutRedirectToLogin(auth, router);
          };
        })
      );
    })
  );
}

function signOutRedirectToLogin(auth: AuthService, router: Router) {
  auth.signOutNoRedirect();
  router.navigate(['/login']);
}