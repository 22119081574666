import { Injectable } from '@angular/core';
import { doc, docData, DocumentReference, Firestore } from '@angular/fire/firestore';
import { JobResult } from '@career-scope/models';
import { BehaviorSubject, Subscription, tap } from 'rxjs';

@Injectable()
export class PathOccupationService {
  occupationLoaded: BehaviorSubject<JobResult | null> = new BehaviorSubject<JobResult | null>(null);
  occupationList: BehaviorSubject<JobResult[]> = new BehaviorSubject<JobResult[]>([]);

  occupationSubscription?: Subscription;

  constructor(private firestore: Firestore) { 
    this.loadOccupations();
  }

  loadOccupations() {

    const occupationListRef = doc(this.firestore, 'occupations/occupationList') as DocumentReference<{occupationList: JobResult[]}>;
    this.occupationSubscription = docData<{occupationList: JobResult[]}>(occupationListRef).pipe(
      tap(list => this.occupationList.next(list?.occupationList || []))
    ).subscribe();
  }

  loadOccupation(onetNumber: string) {
    this.occupationSubscription?.unsubscribe();
    const occupationRef = doc(this.firestore, 'onetOccupations/' + onetNumber) as DocumentReference<JobResult>;
    this.occupationSubscription = docData<JobResult>(occupationRef).pipe(
      tap(occupation => this.occupationLoaded.next(occupation || null))
    ).subscribe();
  }

  unsubscribeOccupation() {
    this.occupationLoaded.next(null);
    this.occupationSubscription?.unsubscribe();
  }
}
