export interface Audio {
  url: string;
  text: string;
  consumerDirectText?: string;
  spanishText: string;
  consumerDirectSpanishText?: string;
  secondary?: number;
}

export const AUDIO: Audio[] = [
  {
    url: '/',
    text: 'Welcome to CareerScope! Our science, built on a long history of vocational research, will provide you with career recommendations based on your specific interests and aptitudes.',
    spanishText: '¡Bienvenido a CareerScope! Nuestra ciencia, construida bajo una larga trayectoria de investigación profesional, le proporcionará recomendaciones profesionales basadas en sus intereses y aptitudes específicas.'
  },
  {
    // Interests not complete
    url: '/interests',
    secondary: 1,
    text: 'By measuring your interests, CareerScope will help you choose the kind of work you will most enjoy. The key is to find jobs where you like most of the work activities and can thrive. The CareerScope Interest Inventory includes a list of over 140 work activities. CareerScope uses your responses to those items to identify occupations that include your preferred work activities.',
    consumerDirectText: 'By measuring your interests, CareerScope will help you choose the kind of work you will most enjoy. The key is to find jobs where you like most of the work activities and can thrive. The CareerScope Interest Inventory includes a list of over 130 work activities. CareerScope uses your responses to those items to identify occupations that include your preferred work activities.',
    spanishText: 'Al medir sus intereses, CareerScope le ayudará a elegir el tipo de trabajo que más le guste. La clave es encontrar trabajos donde le gusten la mayoría de las actividades laborales y pueda prosperar. El Inventario de intereses de CareerScope incluye una lista de más de 140 actividades laborales. CareerScope utiliza sus respuestas a esos elementos para identificar ocupaciones que incluyen sus actividades laborales preferidas.',
    consumerDirectSpanishText: 'Al medir sus intereses, CareerScope le ayudará a elegir el tipo de trabajo que más le guste. La clave es encontrar trabajos donde le gusten la mayoría de las actividades laborales y pueda prosperar. El Inventario de intereses de CareerScope incluye una lista de más de 130 actividades laborales. CareerScope utiliza sus respuestas a esos elementos para identificar ocupaciones que incluyen sus actividades laborales preferidas.'
  },
  {
    // Interests complete, exercises not complete
    url: '/interests',
    secondary: 2,
    text: 'Congratulations! You\'ve completed the CareerScope Interest Inventory. Before you can view your results, you must complete the Aptitude Exercises. Typically, your first response to the Interest Inventory items is the most accurate, but if you feel like you did not provide true responses, you have the option to modify your responses on the interest inventory before moving on to the Aptitude Exercises.',
    spanishText: '¡Felicidades! Ha completado el Inventario de Intereses de CareerScope. Antes de poder ver sus resultados, debe completar los Ejercicios de Aptitud. Normalmente, su primera respuesta a los ítems del Inventario de Intereses es la más precisa, pero si cree que no ha dado respuestas verdaderas, tiene la opción de modificar sus respuestas en el inventario de intereses antes de pasar a los Ejercicios de Aptitud.'
  },
  {
    // Exercises and interests complete
    url: '/interests',
    secondary: 3,
    text: 'Congratulations! You\'ve completed the CareerScope Interest Inventory & Aptitude Exercises. Go to your My Results page to discover your path to successful careers! Typically, your first response to the Interest Inventory items is the most accurate, but if you feel like you did not provide true responses, you have the option to modify your responses on the interest inventory.',
    spanishText: '¡Felicidades! Ha completado el Inventario de Intereses & Ejercicios de Aptitud de CareerScope. ¡Vaya a su página Mis Resultados para descubrir su camino hacia carreras exitosas! Normalmente, su primera respuesta a los ítems del Inventario de Intereses es la más precisa, pero si cree que no ha dado respuestas verdaderas, tiene la opción de modificar sus respuestas en el inventario de intereses.'
  },
  {
    // Exercises not complete
    url: '/exercises',
    secondary: 1,
    text: 'Aptitudes are a person\'s natural ability to learn. By measuring your aptitudes, CareerScope will help you choose the kind of work where you will be most successful at learning and performing the required activities. Every job requires a different set of aptitudes, so the key is to find jobs where your aptitudes are the best match for the work involved. CareerScope uses your scores on various exercises to determine these matches. Click on any of the exercises below to get started.',
    spanishText: 'Las aptitudes son la capacidad natural de una persona para aprender. Al medir sus aptitudes, CareerScope lo ayudará a elegir el tipo de trabajo en el que tendrá más éxito a la hora de aprender y realizar las actividades requeridas. Cada trabajo requiere un conjunto diferente de aptitudes, por lo que la clave es encontrar trabajos en los que sus aptitudes sean las más adecuadas para el trabajo en cuestión. CareerScope utiliza sus puntajes en varios ejercicios para determinar estas coincidencias. Haga clic en cualquiera de los ejercicios a continuación para comenzar.'
  },
  {
    // Exercises complete, interests not complete
    url: '/exercises',
    secondary: 2,
    text: 'Congratulations! You\'ve completed the CareerScope Aptitude Exercises. Before you discover what aptitudes you excel at, you must complete the Interest Questionnaire.',
    spanishText: '¡Felicidades! Ha completado los Ejercicios de Aptitud de CareerScope. Antes de descubrir en qué aptitudes destaca, debe rellenar el Cuestionario de Intereses.'
  },
  {
    // Exercises and interests complete
    url: '/exercises',
    secondary: 3,
    text: 'Congratulations! You\'ve completed the CareerScope Interest Inventory & Aptitude Exercises. Go to your My Results page to discover your path to successful careers!',
    spanishText: '¡Felicidades! Ha completado el Inventario de Intereses & Ejercicios de Aptitud de CareerScope. ¡Vaya a su página Mis Resultados para descubrir su camino hacia carreras exitosas!'
  },
  {
    // Exercises or interests not complete
    url: '/results',
    secondary: 1,
    text: 'CareerScope generates your results after you complete the Interest Inventory and all of the Exercises. Click below to return to those pages to finish any sections that are still incomplete. When you\'re finished, come back here to see your results and download your CareerScope report.',
    spanishText: 'CareerScope genera sus resultados después de completar el Inventario de intereses y todos los Ejercicios. Haga clic a continuación para volver a esas páginas y terminar las secciones que aún estén incompletas. Cuando haya terminado, vuelva aquí para ver sus resultados y descargar su informe de CareerScope.'
  },
  {
    // Exercises and interests complete
    url: '/results',
    secondary: 2,
    text: 'Congratulations! You have completed the CareerScope assessment and are ready to use your results to navigate your unique path. Below is a preview of your Interest and Aptitude results. To see more detailed results and career recommendations, click below to download your full CareerScope report.',
    spanishText: '¡Felicidades! Ha completado la evaluación de CareerScope y está listo para usar sus resultados para navegar por su propio camino. A continuación se muestra una vista previa de los resultados de su interés y aptitud. Para ver resultados más detallados y recomendaciones profesionales, haga clic a continuación para descargar el informe completo de CareerScope.'
  },
  {
    // Alt - Settings enabled to hide evaluee results
    url: '/results',
    secondary: 3,
    text: 'Congratulations! You have completed the CareerScope assessment. Contact your counselor to review your detailed results and career recommendations to begin exploring your unique path.',
    spanishText: '¡Felicidades! Ha completado la evaluación de CareerScope. Póngase en contacto con su consejero para revisar sus resultados detallados y sus recomendaciones profesionales para comenzar a explorar su propio camino.'
  },
  {
    // Object identification loaded 
    url: '/apttest/1',
    secondary: 1,
    text: 'Object identification exercise!! Choose the object that is exactly the same as the target object. Try two practice questions before beginning the exercise!!',
    spanishText: '¡¡Ejercicio de identificación de objetos!! eleccionar el objeto que sea idéntico al objeto de destino. ¡¡Responda dos preguntas de práctica antes de comenzar!!'
  },
  {
    // Object identification start modal timed 
    url: '/apttest/1',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 1 minute to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 1 minuto para responder a tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Object identification start modal untimed 
    url: '/apttest/1',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Object identification end modal 
    url: '/apttest/1',
    secondary: 4,
    text: 'Congratulations! You have completed the object identification exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de identificación de objetos. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Abstract shapes loaded 
    url: '/apttest/3',
    secondary: 1,
    text: 'Abstract shapes exercise!! Choose the shape that is exactly the same as the target shape, even if it is rotated or flipped. Try two practice questions before beginning the exercise!!',
    spanishText: 'Ejercicio de formas abstractas!! Seleccionar la forma que sea idéntica a la forma de destino, incluso si está girada o volteada. ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Abstract shapes start modal timed 
    url: '/apttest/3',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 2 minutes and 30 seconds to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 2 minutos y 30 segundos para responder a tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Abstract shapes start modal untimed 
    url: '/apttest/3',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Abstract shapes end modal 
    url: '/apttest/3',
    secondary: 4,
    text: 'Congratulations! You have completed the object identification exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de formas abstractas. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Clerical matching loaded 
    url: '/apttest/6',
    secondary: 1,
    text: 'Clerical matching exercise!! If both names are exactly the same, click on the word "SAME". If the two names are different, in any way, click on the word "DIFFERENT". Try two practice questions before beginning the exercise!!',
    spanishText: '¡¡Ejercicio de Asignación de Correspondencia!! Si ambos nombres son idénticos, haga clic en la palabra "IGUAL". Si los dos nombres son diferentes, haga clic en la palabra "DIFERENTE". ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Clerical matching start modal timed 
    url: '/apttest/6',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 2 minutes and 30 seconds to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 2 minutos y 30 segundos para responder tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Clerical matching start modal untimed 
    url: '/apttest/6',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Clerical matching end modal 
    url: '/apttest/6',
    secondary: 4,
    text: 'Congratulations! You have completed the clerical matching exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de Asignación de Correspondencia. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Pattern visualization loaded
    url: '/apttest/8',
    secondary: 1,
    text: 'Pattern visualization exercise!! Choose the shape that would be created if the target pattern was folded on the dotted lines. Try two practice questions before beginning the exercise!!',
    spanishText: '¡¡Ejercicio de visualización de patrones!! Seleccionar la forma que se crearía al doblar el patrón objetivo sobre las líneas de puntos. ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Pattern visualization start modal timed
    url: '/apttest/8',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 5 minutes to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 5 minutos para responder tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Pattern visualization start modal untimed
    url: '/apttest/8',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Pattern visualization end modal
    url: '/apttest/8',
    secondary: 4,
    text: 'Congratulations! You have completed the pattern visualization exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de visualización de patrones. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Computation loaded 
    url: '/apttest/10',
    secondary: 1,
    text: 'Computation exercise!! Solve the math problem and choose the correct answer. If the correct answer does not appear, choose "NONE OF THESE". You may use paper and pencil, but not a calculator. Try two practice questions before beginning the exercise!!',
    spanishText: 'Ejercicio de Cómputo!! Resolver el problema matemático y seleccionar la respuesta correcta. Si la respuesta correcta no aparece, elegir "Ninguno de éstos". Puede utilizar papel y lápiz, pero no calculadora. ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Computation start modal timed
    url: '/apttest/10',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 5 minutes to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 5 minutos para responder tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Computation start modal untimed
    url: '/apttest/10',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Computation end modal 
    url: '/apttest/10',
    secondary: 4,
    text: 'Congratulations! You have completed the computation exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de Cómputo. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Numerical reasoning loaded 
    url: '/apttest/12',
    secondary: 1,
    text: 'Numerical reasoning exercise!! Listen to the problem and choose the correct answer. If the correct answer does not appear, choose "NONE OF THESE". You may use paper and pencil, but not a calculator. Try two practice questions before beginning the exercise!!',
    spanishText: 'Ejercicio de razonamiento numérico!! Lee el problema y elige la respuesta correcta. Si no aparece la respuesta correcta, elija "Ninguno de éstos". Puede utilizar papel y lápiz, pero no calculadora. ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Numerical reasoning start modal timed 
    url: '/apttest/12',
    secondary: 2,
    text: 'There are 23 questions in this exercise. You will have 7 minutes to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 23 preguntas en este ejercicio. Usted tendrá 7 minutos para responder tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Numerical reasoning start modal untimed
    url: '/apttest/12',
    secondary: 3,
    text: 'There are 23 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 23 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Numerical reasoning end modal 
    url: '/apttest/12',
    secondary: 4,
    text: 'Congratulations! You have completed the numerical reasoning exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado el ejercicio de razonamiento numérico. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Word meanings loaded 
    url: '/apttest/14',
    secondary: 1,
    text: 'Word meanings exercise!! Choose the word which is most nearly the SAME or most nearly the OPPOSITE in meaning to the target word. Try two practice questions before beginning the exercise!!',
    spanishText: 'El Significados de las Palabras Ejercicio!! Seleccionar la palabra que comparta el significado más PARECIDO o más OPUESTO con la palabra objetivo. ¡Responda dos preguntas de práctica antes de comenzar!'
  },
  {
    // Word meanings start modal timed 
    url: '/apttest/14',
    secondary: 2,
    text: 'There are 30 questions in this exercise. You will have 2 minutes and 15 seconds to answer as many questions as you can. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Usted tendrá 2 minutos y 15 segundos para responder tantas preguntas como puedas. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Word meanings start modal untimed
    url: '/apttest/14',
    secondary: 3,
    text: 'There are 30 questions in this exercise. This exercise will be untimed. Remember: There are no penalties for guessing.',
    spanishText: 'Hay 30 preguntas en este ejercicio. Este ejercicio no tendrá límite de tiempo. Recuerde: No hay penalizaciones por adivinar.'
  },
  {
    // Word meanings end modal 
    url: '/apttest/14',
    secondary: 4,
    text: 'Congratulations! You have completed the word meanings exercise. You are one step closer to discovering your path.',
    spanishText: '¡Felicidades! Ha completado El Significados de las Palabras ejercicio. Está un paso más cerca de descubrir su camino.'
  },
  {
    // Interest test
    url: '/inttest',
    text: 'Listen to each item below. Decide whether you would like, dislike, or are unsure about doing the work activity or task, and click the column for that response. Interests inventory beginning now!!!',
    spanishText: 'Escuche cada elemento a continuación. Lea cada artículo a continuación. Decida si le gustaría, no le gusta o no está seguro de realizar la actividad o tarea del trabajo y haga clic en la columna para ver esa respuesta. ¡¡¡Inventario de intereses a partir de ahora!!!'
  },
];