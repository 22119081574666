import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, catchError, from, of, take, tap } from 'rxjs';
import { EmailFormData } from '../_models/form-data.model';
import { InterestService } from './interest.service';
import { NotificationService } from './notification.service';
import { FormData } from '../_models/form-data.model';
import { AssessmentSettings, Evaluee, EvalueeFeatureFlags, PortalSecuritySettings, PortalSpecificSupportEmailType } from '@career-scope/models';
import { EvalueePathService } from './evaluee-path.service';
import { FeaturesSecurityService } from './features-security.service';
import { AssessmentService } from './assessment.service';
import { addDoc, collection, doc, docData, DocumentReference, Firestore, setDoc } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})
export class EvalueeService {
  locale = inject(LOCALE_ID);
  // Look to replace with a signal in the future
  // evaluee BehaviorSubject starts as null, if it is null then the user is not logged in
  // evaluee BehaviorSubject is undefined if the user is logged in but the evaluee is not found
  // evaluee BehaviorSubject is set to the evaluee object if the evaluee is found
  evaluee = new BehaviorSubject<Evaluee | null | undefined>(null);
  evaluee$: Observable<Evaluee | undefined> = of(undefined);
  evalueeSubscription: Subscription | null = null;

  evalueeProfileCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  dialogOpen = false;

  constructor(
    private is: InterestService,
    private firestore: Firestore,
    private eps: EvalueePathService,
    private fss: FeaturesSecurityService,
    private assessmentService: AssessmentService,
    private notif: NotificationService,
    private functions: Functions,

  ) {
    this.handleEvalueeChanges();
  }

  setEvalueeObservable(portalId: string, evalueeId: string) {
    const evaluee = this.evaluee.value;

    if (evaluee?.portalId === portalId && evaluee?.uid === evalueeId) {
      return;
    }

    // Reset the state of the application
    this.evalueeSubscription?.unsubscribe();

    // Can change evalueeId to test for evaluee not found
    const evalueeDocRef = doc(this.firestore, `portals/${portalId}/evaluees/${evalueeId}`) as DocumentReference<Evaluee>;
    this.evaluee$ = docData(evalueeDocRef);

    this.evalueeSubscription = this.evaluee$.pipe(
      tap((evaluee) => this.evaluee.next(evaluee))
    ).subscribe();

  }

  private setEvalueeNullAndUnsubscribe() {
    this.evaluee.next(null);
    this.evalueeSubscription?.unsubscribe();
  }

  private handleEvalueeChanges(): void {
    this.evaluee.pipe(
      tap((evaluee) => {
        if (!evaluee) {
          this.resetSubjectsInServiceAfterNullEvaluee();
          return;
        }

        // Set evaluee assessment settings into broken out settings. To be replaced in future
        if (evaluee.assessmentSettings) {
          this.loadAssessmentSettings(evaluee.assessmentSettings);
        }

        // TODO: Research whether check profile should be here or in the guard

        //TODO: SET/RESET ASSESSMENT SETTINGS
        // Create an assessmentSettings BehaviorSubject and method (or service) to update the settings
        // If we keep these streamy than components need to async the assessmentSettings
        // We have 11 files using settings coming through evaluee
        // When we update the behavior subject we can address any impacts the change may have
        // Seems odd to "reset" settings each time evaluee is changed.  Should be less if answers/etc. are stored in a different document
        // TODO: CREATE PORTAL SECURITY SETTINGS MODEL THAT WE SET FROM evaluee.assessmentSettings and other flags needed

        this.is.consumerDirectEvaluee.set(evaluee.consumerDirect || false);

        // MyPath Job Info
        this.eps.updateEvalueePathInfo(evaluee);

      })
    ).subscribe();
  }

  loadAssessmentSettings(assessmentSettings: AssessmentSettings) {
    // In the future we shouldn't need to use the OR operator as these values should always be set
    // Portal Security Settings
    const portalSecuritySettings: PortalSecuritySettings = {
      autoLogout: assessmentSettings.autoLogout || false,
      myProfileHideName: assessmentSettings.myProfileHideName || false
    };

    // Evaluee feature flags
    const featureFlags: EvalueeFeatureFlags = {
      survey: assessmentSettings.survey || false,
      viewMyPath: assessmentSettings.viewMyPath || false,
      superUser: assessmentSettings.superUser || false,
      hideResultOnCompletion: assessmentSettings.hideResultOnCompletion || false
    };

    this.fss.updateFeaturesSecurityInfo(portalSecuritySettings, featureFlags);
    this.fss.setExerciseTimeModifier(assessmentSettings.exerciseTimeModifier || 1);
    this.fss.setUntimed(assessmentSettings.untimed || false);
    this.fss.setShowTimer(assessmentSettings.showTimer || true);
  }

  // ! All Evaluee Updates should go through this method
  private saveEvaluee(evaluee: Evaluee) {
    // We use last modified date to determine which records need to be updated in our Evaluee Sets Cached Arrays for Evaluee listings
    evaluee.lastModifiedDate = new Date(Date.now());

    const evalueeRef = doc(this.firestore, `portals/${evaluee.portalId}/evaluees/${evaluee.uid}`) as DocumentReference<Evaluee>;

    // Error can't be caught here, firebase will keep attempting to save
    setDoc(evalueeRef, evaluee, { merge: true });
  }

  //#region Evaluee Profile
  // Executed when loading an evaluee.  If anything required is missing then force profile completion
  // Also executed in teh validate-profile-information guard 
  public myProfileCompleted(loadedEvaluee: Evaluee) {
    if (!loadedEvaluee.fullName || !loadedEvaluee.age || !loadedEvaluee.gender) {
      this.evalueeProfileCompleted.next(false);
      return false;
    }

    this.evalueeProfileCompleted.next(true);
    return true;
  }


  // Stores any updates that evaluee has made to their profile
  updateEvalueeFromProfile(formData: FormData) {
    const updatedEvaluee: Evaluee | null | undefined = this.evaluee.value;
    if (formData && updatedEvaluee) {

      const { fullname = '', gender = null, age = null, currentGrade = null } = formData;

      updatedEvaluee.fullName = fullname;
      updatedEvaluee.lowerCaseFullName = fullname.toLowerCase();
      updatedEvaluee.gender = gender;
      updatedEvaluee.age = age;
      updatedEvaluee.currentGrade = currentGrade;

      updatedEvaluee.lastActivity = 'profile updated';
      updatedEvaluee.lastActivityDate = new Date(Date.now());

      if (updatedEvaluee.status === 'new') {
        updatedEvaluee.status = 'in progress';
      }
      this.assessmentService.saveAssessment({ age: age, gender: gender, currentGrade: currentGrade }, updatedEvaluee.portalId, updatedEvaluee.uid, updatedEvaluee.currentAssessmentId);
      return this.saveEvaluee(updatedEvaluee);
    }

    return null;
  }
  //#endregion

  signOut() {
    this.setEvalueeNullAndUnsubscribe();
  }

  logEvent(event: string, taskId?: number) {
    const currentEvaluee = this.evaluee.value;

    console.log(currentEvaluee?.logonName, taskId ? 'task ID ' + taskId : '', event);
    // TODO: Store this on evaluee for reference and trouble shooting.  May store this in a central collection as well for easy review
  }

  resetSubjectsInServiceAfterNullEvaluee() {
    this.fss.clearFeaturesSecurityInfo();
    this.eps.clearEvalueePathInfo();
    this.assessmentService.setAssessmentNullAndUnsubscribe();
  }

  sendEmail(emailData: EmailFormData) {
    const currentEvaluee = this.evaluee.getValue();
    if (currentEvaluee) {
      const supportEmailRef = collection(this.firestore, 'supportEmails');
      addDoc(supportEmailRef, {
        to: emailData.email,
        template: {
          name: 'support-email',
          data: {
            username: currentEvaluee.logonName,
            portalId: currentEvaluee.portalId,
            uid: currentEvaluee.uid,
            subject: emailData.subject,
            message: emailData.message
          }
        }
      }).then(() => {
        this.notif.openSnackBar('Email queued for delivery!', 'Dismiss', {
          duration: 3 * 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    } else {
      console.log('No evaluee found to send email');
    }
  }

  //#endregion

  saveAudioEnabled(audioEnabled: boolean) {
    if (this.evaluee.value)
      this.saveEvaluee({ ...this.evaluee.value, audioEnabled });
  }

  sendPortalSpecificSupportEmail(message: string, portalId: string | null) {
    if (!portalId || !message) {
      this.notif.openSnackBar('Unable to send support email', 'Dismiss');
      return of();
    }

    const callable = httpsCallable<{ type: PortalSpecificSupportEmailType, portalId: string, message: string; }, string>(this.functions, 'sendSupportEmailToPortal');

    return from(callable({ type: 'login_issues', portalId, message })).pipe(
      take(1),
      tap(() => this.notif.openSnackBar('Support email sent', 'Dismiss')),
      catchError(err => {
        console.error(err);
        this.notif.openSnackBar(err.message, 'Dismiss');
        throw err;
      })
    );
  }
}
