import { Injectable } from '@angular/core';
import { CanActivate, Router, } from '@angular/router';
import { map, Observable, skipWhile } from 'rxjs';
import { EvalueeService } from '../_services/evaluee.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateProfileInformationGuard implements CanActivate {

  constructor(private es: EvalueeService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.es.evaluee.pipe(
      skipWhile(evaluee => evaluee === null || evaluee === undefined),
      map(evaluee => {
        if (!evaluee) {
          return false;
        }

        if (this.es.myProfileCompleted(evaluee)) {
          return true;
        }

        this.router.navigate(['/profile']);
        return false;
      })
    )
  }
}
